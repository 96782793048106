import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  List,
  ListItem,
  Box,
  Spinner,
  Center,
  Input,
  Stack,
  FormControl,
  FormLabel,
  Divider,
  AbsoluteCenter,
  Wrap,
  WrapItem,
  Tag,
} from "@chakra-ui/react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { useEffect, useState } from "react";
import { getHostDomain } from "../../utils/utils";
import Select from "react-select";
import useToolUserRunStaticFunction from "../Tools/useToolUserRunStaticFunction";


export function ContactFieldsList({ isOpen, onClose, getValue, setValue, watch }) {
  const baseUrl = getHostDomain();
  const { attributes = {} } = watch();
  const { tool_id, prompt_tool_user_id } = attributes;

  const defaultContactField = [
    {
      label: "First Name",
      value: "first_name",
      type: "default"
    },
    {
      label: "Last Name",
      value: "last_name",
      type: "default"
    },
    { label: "WhatsApp", value: "channels.whatsapp", type: "custom" },
    { label: "Instagram", value: "channels.instagram", type: "custom"},
    { label: "Web", value: "channels.web", type: "custom" },
    { label: "Microsoft Teams", value: "channels.microsoft_teams", type: "custom" },
    { label: "Telephone", value: "channels.telephone", type: "custom" },
  ];
  const [contactField, setContactField] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [CRMOptions, setCRMOptions] = useState([]);
  const [toolConfigurationOptions, setToolConfigurationOptions] = useState([]);
  const [toolProviderName, setToolProviderName] = useState("")

  const { response, status } = useToolUserRunStaticFunction({
    tool_user_id: prompt_tool_user_id,
    staticFnPayload: {
      function_name: "get_fields",
    },
  });

  useEffect(() => {
    if (response && prompt_tool_user_id) {
      const fieldData = Object.keys(response).reduce((acc, key) => {
        if (key !== "additionalEmails" && key !== "additionalPhones") {
          acc.push({
            value: `${toolProviderName}.${key}`,
            label: key,
            type: `${toolProviderName}`
          });
        }
        return acc;
      }, []);

      setContactField([...fieldData]);
    }
  }, [response, prompt_tool_user_id]);

  const endpoint = `/api/v1/contact_custom_field/list?page=1&size=50`;

  const getCRMList = async () => {
    try {
      setLoading(true);
      const response = await fetchController(
        baseUrl + "/api/v1/tool/tools/conversation_sync/list",
        "GET"
      );
      const options = response.data.map((opt) => ({
        value: opt.id,
        label: opt.name,
        data: opt
      }));

      setCRMOptions(options);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const hitFetch = async () => {
    try {
      setLoading(true);
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "GET");
      const custom_fields = response.data.items;
      const fieldData = custom_fields.map((item) => {
        return {
          value: `custom_fields.${item.custom_field_name}`,
          label: item.custom_field_name,
        };
      });

      setContactField([...defaultContactField, ...fieldData]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCRMList()
    hitFetch();
  }, []);

  const getToolConfigurationList = async (toolId) => {
    try {
      setLoading(true);
      const response = await fetchController(
        baseUrl + `/api/v1/tool/${toolId}/LinkToolUser`,
        "GET"
      );
      const options = response.data?.items.map((opt) => ({
        value: opt.id,
        label: opt.name,
      }));

      setToolConfigurationOptions(options);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tool_id) {
      getToolConfigurationList(tool_id);
    }
  }, [tool_id]);

  const labelChange = (field) => {
    let updatedValue = ""
    if(field.type === toolProviderName) {
      updatedValue = getValue.replace("/", `{{${field.value}}}`);
    } else {
      updatedValue = getValue.replace("/", `{{contact.${field.value}}}`);
    }
    setValue("systemPrompt", updatedValue);
    onClose();
  };

  const handleClose = () => {
    if (getValue.includes("/") && !contactField.some((field) => getValue.includes(field.value))) {
      const updatedValue = getValue.replace("/", "");
      setValue("systemPrompt", updatedValue);
    }
    onClose();
  };

  const filteredContactFields = contactField.filter((field) =>
    field.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleCRMSelect = (selectedOpt) => {
    setValue("attributes.tool_id", selectedOpt.value);
    setToolProviderName(selectedOpt.data.tool_provider)
  };

  const handleToolConfigSelect = ({ value }) => {
    if (value) {
      setValue("attributes.prompt_tool_user_id", value);
    } else {
      setValue("attributes.prompt_tool_user_id", value);
      hitFetch();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} size="xl">
        <ModalOverlay />
        <ModalContent height="auto" width="100%" maxWidth="900px">
          <ModalHeader>Contact Fields</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxHeight="500px" overflowY="scroll">
            <FormControl>
              <Input
                placeholder="Search..."
                mb={4}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </FormControl>

            <Stack direction="row" spacing={2} py={2}>
              <FormControl>
                <Select
                  placeholder="Select Supported CRM"
                  value={CRMOptions.find((opt) => opt.value === tool_id)}
                  options={CRMOptions}
                  onChange={handleCRMSelect}
                  isLoading={loading}
                />
              </FormControl>
              <FormControl>
                <Select
                  placeholder="Select Tool Configuration"
                  value={toolConfigurationOptions.find(
                    (opt) => opt.value === prompt_tool_user_id
                  )}
                  options={[
                    { value: "", label: "Select Tool Configuration" },
                    ...toolConfigurationOptions,
                  ]}
                  onChange={handleToolConfigSelect}
                  isLoading={loading}
                />
              </FormControl>
            </Stack>

            <Box position="relative" padding="10">
              <Divider />
              <AbsoluteCenter bg="white" px="4">
                <Tag>Fields</Tag>
              </AbsoluteCenter>
            </Box>

            {status === "loading" || loading ? (
              <Center>
                <Spinner size="xl" />
              </Center>
            ) : (
              <Wrap spacing={3}>
                {filteredContactFields.map((field, index) => (
                  <WrapItem key={field.value}>
                    <Box
                      cursor="pointer"
                      transition="background-color 0.2s ease-in-out"
                      _hover={{ bg: "gray.200" }}
                      p={3}
                      borderRadius="md"
                      border="1px"
                      borderColor="gray.200"
                      onClick={() => {
                        labelChange(field);
                      }}
                    >
                      {field.label}
                    </Box>
                  </WrapItem>
                ))}
              </Wrap>
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
