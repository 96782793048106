import { Box, Flex, Heading, Text, Select, useColorModeValue } from "@chakra-ui/react";
import Layout from "../Layout/Layout";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import Loader from "../../utils/Loader/Loader";
import IframeWidget from "./IframeWidget";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { getHostDomain } from "../../utils/utils";
// import Select from "react-select";
export default function PlaygroundPage() {
  const baseUrl = getHostDomain();
  const [widgets, setWidgets] = useState([]);
  const [status, setStatus] = useState("idle");
  useEffect(() => {
    (async () => {
      setStatus("loading");
      const endpoint = "/api/v1/widget/get_my_details?page=1&size=50";
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "GET");
      setWidgets(response.data.items);
      setStatus("success");
    })();
  }, []);
  const loading = status === "loading";
  const [widget, setWidget] = useState({
    id: "",
    type: "",
  });
  const onChangeWidget = (widget) => {
    setWidget({
      id: widget.id,
      type: widget.type,
    });
  };
  const selectPlaceholder = widgets.length
    ? "Select widget to start the chat"
    : "Please create a widget first";

  const agency = useContext(AgencyContext);
  const { textColor, colorPreset } = agency;
  return (
    <Layout>
      <Box border="1px" h="90svh" p="5" borderRadius="md" overflowY="auto">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Flex justifyContent="start" alignItems="center">
                <Heading mb={2} color={textColor} fontSize="xl">
                Playground
              </Heading>
            </Flex>
            {/* <Select
              disabled={widgets.length === 0}
              placeholder={selectPlaceholder}
              onChange={(widget) => {
                onChangeWidget({
                  id: widget.value,
                  type: widget.type,
                });
              }}
              options={widgets.map((widget) => ({
                label: `${widget.name} [${widget.id}]`,
                value: widget.id,
                isDisabled: widget.widget_type === "phone",
                type: widget.widget_type,
              }))}
            /> */}
              <Select disabled={widgets.length === 0} placeholder={selectPlaceholder} onChange={(e) => {
                const selectedOption = e.target.selectedOptions[0];
                const value = selectedOption.value;
                const widgettype = selectedOption.getAttribute("widgettype");
                onChangeWidget({
                  id: value,
                  type: widgettype,
                });
              }}>
                {widgets.map((widget) => (<option value={widget.id} disabled={widget.widget_type === "phone"} widgettype={widget.widget_type}>
                  {`${widget.name} [${widget.id}]`}
                </option>))}

              </Select>
              
            {widget.id ? (
              <Flex alignItems="center" justifyContent="center" mt={2}>
                <IframeWidget widgetId={widget.id} widgetType={widget.type} />
              </Flex>
            ) : null}
          </>
        )}
      </Box>
    </Layout>
  );
}
