import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import { IoMicOutline } from "react-icons/io5";

export default function WebcallRepresentation({ watch }) {
  const { style_params } = watch();
  const bgColor = style_params?.theme?.magicBall?.bgColor;
  return (
    <Box>
      <Flex gap={2} justifyContent={"center"} alignItems={"center"}>
        <Image
          src={style_params.logo}
          fallbackSrc={"/logo/favicon.png"}
          width={30}
        />
        <Heading fontSize={30}>{style_params?.header_name}</Heading>
      </Flex>
      <Flex alignItems={"center"} justifyContent={"center"} height={"xl"}>
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          width={150}
          height={150}
          borderRadius={"full"}
          background={`radial-gradient(
      54.75% 54.75% at 50% 50%,
      rgba(247, 244, 244, 0.22) 70.24%,
      ${bgColor}
    ),
    linear-gradient(135deg, rgba(255, 255, 255, 0.54) 0%, ${bgColor}),
    radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.22) 0%, ${bgColor} 90.5%)`}
        >
          <IoMicOutline size={50} color={bgColor} />
        </Flex>
      </Flex>
      {style_params?.remove_branding ? null : (
        <Box gap={2} textAlign={"center"}>
          <Text>
            <span>PoweredBy  </span>
            <strong>{style_params?.agency_name}</strong>
          </Text>
        </Box>
      )}
    </Box>
  );
}
