import React from "react";
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  ModalBody,
  ModalFooter,
  Spinner,
  Stack,
  Tag,
  TagLabel,
  Text,
  Textarea,
  useDisclosure
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { getHostDomain } from "../../../utils/utils";
import CustomDuration from "../CustomDuration";
import SelectAvailability from "../SelectAvailability";
import SelectTimzone from "../SelectTimezone";
import useToolUserRunStaticFunction from "../useToolUserRunStaticFunction";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function GoogleCalendarModal({ onClose, toolUserForm }) {
  const baseUrl = getHostDomain();
  const { watch, setValue, register, handleSubmit, formState } = toolUserForm;
  const { tool_user_id, credentials = {} } = watch();

  const { configuration = {} } = credentials;
  const { response, status } = useToolUserRunStaticFunction({
    tool_user_id,
    staticFnPayload: {
      function_name: "get_calendars",
    },
  });
  const googleCalendarOptions = response
    ? response.map((item) => ({
        value: item.id,
        label: item.id,
      }))
    : [];

  const navigate = useNavigate();
  const availability = configuration?.availability || {};

  const onSubmitGoogleCalendarForm = async (data) => {
    try {
      const checkIfSlotIsSelected = (day) => !(availability[day] || []).length;
      if (Object.keys(availability).every(checkIfSlotIsSelected)) {
        toast.error("Select at least one slot");
        onToggle();
        return;
      }
      await fetchController(
        baseUrl + `/api/v1/tool/${data.tool_user_id}/LinkToolUser`,
        "PUT",
        {
          credentials,
        }
      );
      toast.success("Tool setup done");
      onClose();
      navigate(`/tools`);
    } catch (error) {
      toast.error("Some error occured");
    }
  };
  const { onToggle, isOpen } = useDisclosure();

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitGoogleCalendarForm)}>
        <ModalBody>
          {status === "loading" ? (
            <Flex justifyContent={"center"} alignItems={"center"}>
              <Spinner />
            </Flex>
          ) : (
            <Stack spacing={2}>
              <FormControl isRequired>
                <FormLabel>Select Calendar</FormLabel>
                <Select
                  value={googleCalendarOptions.find(
                    (calendarOption) =>
                      calendarOption.value === configuration.calendar_id
                  )}
                  onChange={({ value }) => {
                    setValue("credentials.configuration.calendar_id", value);
                  }}
                  options={googleCalendarOptions}
                  required
                />
              </FormControl>
              <FormControl>
                <FormLabel>CC Email on Invites</FormLabel>
                <Input
                  type="email"
                  {...register(
                    "credentials.configuration.appointment_email_copy"
                  )}
                />
              </FormControl>
              <CustomDuration toolUserForm={toolUserForm} />
              <SelectTimzone toolUserForm={toolUserForm} />
              <FormControl>
                <FormLabel>Invite Title</FormLabel>
                <Textarea {...register("credentials.configuration.summary")} />
              </FormControl>
              <FormControl>
                <FormLabel>Invite Description</FormLabel>
                <ReactQuill
                  value={watch("credentials.configuration.invite_description") || ""}
                  onChange={(value) => {
                    setValue("credentials.configuration.invite_description", value);
                  }}
                  theme="snow"
                />
              </FormControl>
              <Flex justifyContent={"flex-start"} alignItems={"center"}>
                <Button onClick={onToggle} size={"sm"} colorScheme="yellow">
                  Select Availability
                </Button>
              </Flex>
              <Divider />
              <Text>Available on</Text>
              <HStack>
                {Object.keys(availability)
                  .filter((day) => availability[day].length)
                  .map((day) => (
                    <Tag key={day} variant="subtle" colorScheme="cyan">
                      <TagLabel>{day}</TagLabel>
                    </Tag>
                  ))}
              </HStack>
              <Text fontStyle={"italic"}>
                Your calendar will be used in real time to find available free
                slots within these selected time windows.
              </Text>
            </Stack>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={formState.isSubmitting}
            colorScheme="blue"
            mr={3}
            type="submit"
          >
            Finish
          </Button>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </form>
      {isOpen ? (
        <SelectAvailability
          isOpen={isOpen}
          onClose={onToggle}
          toolUserForm={toolUserForm}
        />
      ) : null}
    </>
  );
}
