import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useContext } from "react";
import { SettingContext } from "../Settings/Reducer/SettingProvider";
import { getHostDomain } from "../../utils/utils";

export default function CallWidgetCustomizeForm({ form, widget }) {
  const baseUrl = getHostDomain();
  const { register, handleSubmit, formState } = form;
  const { widgetId } = useParams();
  const onFormSubmit = handleSubmit(async (data) => {
    const widgetToUpdate = {
      ...widget,
      style_params: data,
    };
    await fetchController(
      baseUrl + `/api/v1/widget/${widgetId}`,
      "PUT",
      widgetToUpdate
    );
    toast.success("Widget updated");
  });
  const { state: plan } = useContext(SettingContext);
  const remove_branding = !plan?.remove_branding;
  return (
    <form onSubmit={onFormSubmit}>
      <Stack>
        <FormControl isRequired>
          <FormLabel>Header Name</FormLabel>
          <Input {...register("header_name")} />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Logo</FormLabel>
          <Input type="url" {...register("logo")} />
        </FormControl>
        <FormControl isRequired>
          <Checkbox
            title={remove_branding ? "Upgrade to gold or above plan" : null}
            isDisabled={remove_branding}
            {...register("remove_branding")}
          >
            Remove branding
          </Checkbox>
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Confirmation Message</FormLabel>
          <Input
            {...register("confirmation_message", {
              required: true,
            })}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Button Label</FormLabel>
          <Input
            {...register("button_label", {
              required: true,
            })}
          />
        </FormControl>
        <SimpleGrid minChildWidth={200} gap={2}>
          <FormControl isRequired>
            <FormLabel>Background color</FormLabel>
            <Input type="color" {...register("theme.app.backgroundColor")} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Button color</FormLabel>
            <Input type="color" {...register("theme.button.backgroundColor")} />
          </FormControl>
        </SimpleGrid>
        <Link to={`/forms/${widget.attributes.form_id}`}>Customize form</Link>
        <Button
          loadingText="Submitting"
          isLoading={formState.isSubmitting}
          colorScheme="blue"
          type="submit"
        >
          Submit
        </Button>
      </Stack>
    </form>
  );
}
