import React, { useContext } from "react";
import "./Message.css";
import dateFormat from "../../utils/DateFormat/dateFormat";
import { Box, Text, theme, useColorModeValue } from "@chakra-ui/react";
import AudioPlayback from "./AudioPlayback";
import ChatRecipientIcon from "./ChatRecipientIcon";
import MessageText from "./MessageText";
import dateTimeFormat from "../../utils/DateFormat/dateTimeFormat";
import moment from "moment";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import TilesList from "./TilesList";
const Message = (props) => {
  const className = `message ${props.messageData.sender_type}`;
  const isMyMessage = props.messageData.sender_type === "user";
  const wrapperClassName = `${
    isMyMessage ? "message__wrapper" : "message__wrappperMe"
  }`;
  const conversationDataSource = props.messageData?.data_sources || [];
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, cardBg: bg, colorPreset } = agency;

  // const color_preset = agency?.data?.branding?.color_preset
  const boatMsgBgColor = useColorModeValue("#E8E8E8", theme.colors.gray[700]);
  const useMsgBgColor = useColorModeValue(colorPreset || "#3182ce", textColor);
  const chatTimeColor = useColorModeValue("grey", theme.colors.white);
  return (
    <div className={className} key={props?.key}>
      {isMyMessage ? null : (
        <ChatRecipientIcon
          children={<img src={"/logo/favicon.png"} height={32} width={32} />}
        />
      )}
      <div
        className={`message__timestamp message__timestamp__${props.messageData.sender_type}`}
        
      >
        {/* <div className={wrapperClassName} style={{ border: "1px solid red", backgroundColor: isMyMessage ? "red":"green" }}>
          <MessageText text={props?.messageData?.text} />
          {props.messageData?.voice_base64 ? (
            <AudioPlayback audioBinary={props.messageData?.voice_base64} />
          ) : null}
        </div> */}
        <Box className={wrapperClassName} bg={isMyMessage ?useMsgBgColor:boatMsgBgColor}>
          <MessageText text={props?.messageData?.text} />
          {props.messageData?.voice_base64 ? (
            <AudioPlayback audioBinary={props.messageData?.voice_base64} />
          ) : null}
        </Box>
        <TilesList bgColor={boatMsgBgColor} tiles={conversationDataSource} isMyMessage={isMyMessage}/>
        <small style={{ color: chatTimeColor }}>
          {new Intl.DateTimeFormat(navigator.language, {
            dateStyle: "short",
            timeStyle: "short",
          }).format(new Date(props?.messageData?.created_at))}
        </small>
      </div>
    </div>
  );
};

export default Message;
