import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { AiOutlineAppstoreAdd, AiOutlineImport } from "react-icons/ai";
import { GoPlus } from "react-icons/go";
import {
  BsFillTelephoneFill,
  BsFillTelephoneXFill,
  BsMicrosoftTeams,
} from "react-icons/bs";
import { FaSlack, FaWhatsapp } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { IoCallSharp } from "react-icons/io5";
import { SiMicrosoftexcel } from "react-icons/si";
import { toast } from "react-toastify";
import { formatDateWithAmPm } from "../../utils/DateFormat/dateFormatWithTimeAmPm";
import { fetchController } from "../../utils/FetchController/fetchController";
import Loader from "../../utils/Loader/Loader";
import AddContact from "../Modals/AddContact";
import Table from "../Tables/Table";
import BulkImportContactModal from "./BulkImportContactModal";
import StartCampaignModal from "./StartCampaignModal";
import Paginator from "../../utils/Loader/Paginator";
import { useNavigate } from "react-router-dom";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { getHostDomain } from "../../utils/utils";
const ContactTable = () => {
  const baseUrl = getHostDomain();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [status, setStatus] = useState("idle");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isInitialLoad, setIsInitialLoad] = useState(true); // New state variable
  
  const [tableData, setTableData] = useState([]);
  const [heads, setHeads] = useState([]);
  const endpoint = `/api/v1/contact/list?size=10&page=${page}`;
  const url = baseUrl;
  const hitFetch = async () => {
    try {
      setStatus("loading");
      const urlHit = url + endpoint;

      const getData = await fetchController(urlHit, "GET");
      setPages(getData?.data?.pages);
      if (getData?.data) {
        const updatedData = getData.data.items.map((item) => {
          return {
            value: item,
            status: false,
            currentStatus: false,
            name: (item.first_name || "-") + " " + (item.last_name || "-"),
            email: item.email,
            //  channel: item.channel,
            channels: (
              <Flex gap={2} direction={"row"} wrap={"nowrap"}>
                <Box>
                  {item.channels?.telephone ? (
                    <>
                      <Tooltip
                        label={item.channels?.telephone}
                        aria-label="A tooltip"
                      >
                        <span>
                          <BsFillTelephoneFill
                            onClick={() => {
                              navigator.clipboard.writeText(
                                item.channels?.telephone
                              );
                              toast.success("Copied");
                            }}
                            cursor={"pointer"}
                            title={item.channels?.telephone}
                            color="#0f52b9"
                          />
                        </span>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <BsFillTelephoneXFill />
                    </>
                  )}
                </Box>
                <Box>
                  {item.channels?.whatsapp ? (
                    <>
                      <Tooltip
                        label={item.channels?.whatsapp}
                        aria-label="A tooltip"
                      >
                        <img
                          onClick={() => {
                            navigator.clipboard.writeText(
                              item.channels?.whatsapp
                            );
                            toast.success("Copied");
                          }}
                          cursor={"pointer"}
                          src="/icons/whatsapp.svg"
                          style={{ width: 18, height: 18, cursor: "pointer" }}
                        />
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <FaWhatsapp />
                    </>
                  )}
                </Box>
                <Box>
                  {item.channels?.instagram ? (
                    <>
                      {" "}
                      <Tooltip
                        label={item.channels?.instagram}
                        aria-label="A tooltip"
                      >
                        <img
                          onClick={() => {
                            navigator.clipboard.writeText(
                              item.channels?.instagram
                            );
                            toast.success("Copied");
                          }}
                          src="/icons/instagram.svg"
                          style={{ width: 18, height: 18, cursor: "pointer" }}
                        />
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <FaInstagram />
                    </>
                  )}
                </Box>
                <Box>
                  {item.channels?.slack ? (
                    <>
                      <Tooltip
                        label={item?.channels.slack}
                        aria-label="A tooltip"
                      >
                        <img
                          onClick={() => {
                            navigator.clipboard.writeText(item.channels?.slack);
                            toast.success("Copied");
                          }}
                          src="/icons/slack.svg"
                          style={{ width: 18, height: 18, cursor: "pointer" }}
                        />
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <FaSlack />
                    </>
                  )}
                </Box>
                <Box>
                  {item.channels?.microsoft_teams ? (
                    <>
                      <Tooltip
                        label={item.channels?.microsoft_teams}
                        aria-label="A tooltip"
                      >
                        <img
                          onClick={() => {
                            navigator.clipboard.writeText(
                              item.channels?.microsoft_teams
                            );
                            toast.success("Copied");
                          }}
                          cursor={"pointer"}
                          src="/icons/teams.svg"
                          style={{ width: 18, height: 18, cursor: "pointer" }}
                        />
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <BsMicrosoftTeams />
                    </>
                  )}
                </Box>
              </Flex>
            ),

            ["First added on"]: formatDateWithAmPm(item.created_at),
            ["Last seen"]: formatDateWithAmPm(item.last_seen),
            id: item.id,
          };
        });

        const heads = [
          "status",
          "name",
          "email",
          "channels",
          "First added on",
          "Last seen",
        ];
        const columns = heads.map((key) => ({
          Header: key,
          accessor: key,
          Filter: ({ column }) => <input {...column.filterProps} />,
        }));
        setTableData(updatedData);
        setHeads(columns);
        setIsInitialLoad(false); // Set isInitialLoad to false after the initial load
      }
      setStatus("success");
    } catch (error) {
      setStatus("failure");
    }
  };

  useEffect(() => {
    hitFetch();
  }, [page]);

  const loading = status === "loading";
  const tableRows = tableData.map((row, index) => ({
    ...row,
    status: (
      <Checkbox
        isChecked={row.currentStatus}
        onChange={(e) => {
          setTableData(
            tableData.map((tableRow, currentIndex) => ({
              ...tableRow,
              currentStatus:
                index === currentIndex
                  ? !tableRow.currentStatus
                  : tableRow.currentStatus,
            }))
          );
        }}
      />
    ),
  }));
  const csvData = tableData
    .filter((row) => row.currentStatus)
    .map(({ status, channels, currentStatus, ...row }) => row);
  const {
    isOpen: isCampaignModalOpen,
    onOpen: openCampaignModal,
    onClose: closeCampaignModal,
  } = useDisclosure();
  const clearStatusFromTable = () =>
    setTableData(
      tableData.map((tableRow) => ({
        ...tableRow,
        currentStatus: false,
      }))
    );
  const {
    isOpen: isBulkImportModalOpen,
    onClose: closeBulkImportModal,
    onOpen: openBulkImportModal,
  } = useDisclosure();
  const onGotofirstPage = () => setPage(1);
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme,colorPreset } = agency;
  
  return (
    <>
      <Box overflowY="auto" p="5">
        <Flex mb="4" justifyContent={"space-between"}>
          <Heading color={textColor} fontSize="xl">
            Contacts
          </Heading>
          <Button
            leftIcon={<GoPlus/>}
            colorScheme={buttonColorScheme}
            onClick={() => {
              onOpen();
            }}
            size={"sm"}
          >
            Add Contact
          </Button>
        </Flex>
        <Flex gap={4} marginBlock={3} justifyContent={"space-between"}>
          <Box>
            <Button
              onClick={openCampaignModal}
              colorScheme={buttonColorScheme}
              isDisabled={!csvData.length}
              leftIcon={<IoCallSharp />}
              size={"sm"}
            >
              Start Outbound Calls{" "}
            </Button>
          </Box>
          <Box>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                colorScheme={buttonColorScheme}
                size={"sm"}
              >
                Bulk
              </MenuButton>
              <MenuList>
                <CSVLink data={csvData} style={{ textDecoration: "none" }}>
                  <MenuItem
                    icon={<SiMicrosoftexcel />}
                    isDisabled={!csvData.length}
                  >
                    Export CSV
                  </MenuItem>
                </CSVLink>
                <MenuItem
                  icon={<AiOutlineImport />}
                  onClick={openBulkImportModal}
                  css={`
                    &:hover {
                      color: #1675e0;
                    }
                  `}
                >
                  Import CSV
                </MenuItem>
              </MenuList>
            </Menu>

            <Button
              colorScheme={buttonColorScheme}
              size={"sm"}
              leftIcon={<AiOutlineAppstoreAdd />}
              onClick={() => navigate("/contacts/custom-field")}
              ml={2}
            >
              Custom Fields
            </Button>
          </Box>
        </Flex>
        {loading ? (
          <Loader />
        ) : (
          <Box height="auto">
            <Table
              fetch={hitFetch}
              columns={heads}
              data={tableRows}
              table="contact"
            />
            <Flex
              justifyContent={"flex-end"}
              alignItems={"center"}
              marginBlock={2}
            >
              <Paginator
                colorScheme={buttonColorScheme}
                onChangePage={setPage}
                page={page}
                pages={pages}
              />
            </Flex>
          </Box>
        )}
      </Box>
      {isOpen && (
        <AddContact
          onGotofirstPage={onGotofirstPage}
          fetchContact={hitFetch}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        />
      )}
      {csvData.length ? (
        <StartCampaignModal
          clearStatusFromTable={clearStatusFromTable}
          isOpen={isCampaignModalOpen}
          onClose={closeCampaignModal}
          contacts={csvData}
        />
      ) : null}
      {isBulkImportModalOpen ? (
        <BulkImportContactModal
          fetchContacts={hitFetch}
          onGotofirstPage={onGotofirstPage}
          isOpen={isBulkImportModalOpen}
          onClose={closeBulkImportModal}
        />
      ) : null}
    </>
  );
};

export default ContactTable;
