import { Button, ButtonGroup } from "@chakra-ui/react";

export default function Paginator({ onChangePage, page, pages, colorScheme }) {
  return (
    <ButtonGroup justifyContent={"center"} alignItems={"center"} gap={3}>
      <Button
        colorScheme={colorScheme}
        size={"sm"}
        isDisabled={page <= 1}
        onClick={() => {
          onChangePage((prev) => prev - 1);
        }}
      >
        Previous
      </Button>
      <code>
        {page} / {pages}
      </code>
      <Button
        colorScheme={colorScheme}
        size={"sm"}
        isDisabled={page >= pages}
        onClick={() => {
          onChangePage((prev) => prev + 1);
        }}
      >
        Next
      </Button>
    </ButtonGroup>
  );
}
