import React, { useEffect, useState } from "react";
import { LiaToolsSolid } from "react-icons/lia";

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormLabel,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { fetchController } from "../../utils/FetchController/fetchController";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { AddIcon, DeleteIcon, MinusIcon } from "@chakra-ui/icons";
import { clipText, getHostDomain } from "../../utils/utils";

const AddUseTool = ({
  isOpen,
  onClose,
  assistantToEdit,
  loadAssistantTool,
  linkAssistantTools,
}) => {
  const baseUrl = getHostDomain();
  const { setValue, getValues, reset, watch } = useForm({
    defaultValues: {
      tool: {},
      toolUser: [],
    },
  });

  const [connectedTools, setConnectedTools] = useState({});
  const [toolUserList, setToolUsersList] = useState([]);
  const [toolOptions, setToolOptions] = useState([]);
  const [toolUserOptions, setToolUserOptions] = useState([]);
  const [toolFunctionCount, setToolFunctionCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [toolNameMap, setToolNameMap] = useState(new Map());
  const [toolUserNameMap, setToolUserNameMap] = useState(new Map());
  const [toolFunctionMap, setToolFunctionMap] = useState(new Map());

  const handleClose = () => {
    reset();
    onClose();
  };

  const getMapForLinkAssistantTools = (linkAssistantTools) => {
    const map = new Map();
    for (const linkAssistantTool of linkAssistantTools) {
      map.set(linkAssistantTool.tool_user_id, linkAssistantTool.id);
    }
    return map;
  };

  const getMapForToolUser = (toolUsers) => {
    const map = new Map();
    const newToolUserNameMap = new Map(toolUserNameMap);

    for (const toolUser of toolUsers) {
      map.set(toolUser.tool_id, toolUser.id);
      newToolUserNameMap.set(toolUser.id, toolUser);
    }
    setToolUserNameMap(newToolUserNameMap);
    return map;
  };

  const loadToolList = async () => {
    setLoading(true);
    try {
      const toolListRes = await fetchController(
        baseUrl + "/api/v1/tool/list",
        "GET"
      );

      const toolUserRes = await fetchController(
        baseUrl +
          `/api/v1/tool/LinkToolUser/list?size=100`,
        "GET"
      );
      setToolUsersList(toolUserRes?.data?.items || []);

      await loadToolFunctionList(toolUserRes?.data?.items);

      const map = getMapForToolUser(toolUserRes?.data?.items);

      const newToolNameMap = new Map(toolNameMap);

      const tempOption = toolListRes?.data?.items?.reduce((acc, item) => {
        newToolNameMap.set(item.id, {
          name: item.name,
          logoUrl: item?.logo_url,
        });

        if (map.get(item.id)) {
          acc.push({
            value: item.id,
            label: item.name,
          });
        }
        return acc;
      }, []);

      setToolNameMap(newToolNameMap);
      setToolOptions(tempOption);
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleToolUserPreSelect = async () => {
    setLoading(true);
    try {
      let preSelectedOptionIds = [];

      if (watch("tool")?.value) {
        const assistantResponse = await fetchController(
          baseUrl +
            `/api/v1/assistant/${assistantToEdit?.id}/${
              watch("tool")?.value
            }/LinkAssistantTool`,
          "GET"
        );

        const map = getMapForLinkAssistantTools(assistantResponse?.data?.items);

        const tempOption = toolUserList?.reduce((acc, item) => {
          if (watch("tool")?.value === item?.tool_id) {
            acc.push({
              ...item,
              value: item.id,
              label: item.name,
              linkAssistantTool: map.get(item.id) || null,
            });
          }
          return acc;
        }, []);

        setToolUserOptions(tempOption);

        let preSelectedOptions = [];

        for (const opt of tempOption) {
          if (opt.linkAssistantTool !== null) {
            preSelectedOptions.push(opt);
            preSelectedOptionIds.push(opt.id);
          }
        }

        if (preSelectedOptions?.length) {
          setValue("toolUser", preSelectedOptions || null);
        }
      }
      await loadToolFunctionList(toolUserList, preSelectedOptionIds);
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  async function loadToolFunctionList(
    toolUserData,
    preSelectedUserToolIds = []
  ) {
    const payload = toolUserData?.reduce((acc, toolData) => {
      acc.push(toolData.id);
      return acc;
    }, []);

    try {
      const response = await fetchController(
        baseUrl +
          `/api/v1/tool/LinkToolUser/bulk_list_functions`,
        "POST",
        payload
      );

      let count = 0;

      const newToolFunctionMap = new Map(toolFunctionMap);
      for (let current of response?.data) {
        newToolFunctionMap.set(current.tool_user_id, current.tool_functions);

        if (preSelectedUserToolIds?.includes(current.tool_user_id)) {
          count = count + current.tool_functions.length;
        }
      }

      setToolFunctionMap(newToolFunctionMap);
      setToolFunctionCount(count);
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
    }
  }

  useEffect(() => {
    if (toolNameMap.size && toolUserNameMap.size && toolFunctionMap.size) {
      if (linkAssistantTools?.length) {
        let obj = {};
        linkAssistantTools.map((linkTool) => {
          if (obj[linkTool?.tool_id]) {
            obj = {
              ...obj,
              [linkTool.tool_id]: [
                ...obj[linkTool?.tool_id],
                {
                  tool: toolNameMap.get(linkTool.tool_id),
                  toolUserObj: toolUserNameMap.get(linkTool.tool_user_id),
                  toolFunction: toolFunctionMap.get(linkTool.tool_user_id),
                  linkAssistantToolId: linkTool.id,
                },
              ],
            };
          } else {
            obj = {
              ...obj,
              [linkTool.tool_id]: [
                {
                  tool: toolNameMap.get(linkTool.tool_id),
                  toolUserObj: toolUserNameMap.get(linkTool.tool_user_id),
                  toolFunction: toolFunctionMap.get(linkTool.tool_user_id),
                  linkAssistantToolId: linkTool.id,
                },
              ],
            };
          }
        });
        setConnectedTools(obj);
      } else {
        setConnectedTools({});
      }
    }
  }, [toolNameMap, toolFunctionMap, toolUserNameMap, linkAssistantTools]);

  useEffect(() => {
    loadToolList();
  }, []);

  const addToolUser = async (toolId, orgId, id) => {
    try {
      setLoading(true);
      await fetchController(
        baseUrl +
          `/api/v1/assistant/${assistantToEdit?.id}/${toolId}/LinkAssistantTool`,
        "POST",
        {
          org_id: orgId,
          tool_id: toolId,
          assistant_id: assistantToEdit?.id,
          tool_user_id: id,
        }
      );
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const removeToolUser = async (toolId, linkAssistantToolId) => {
    try {
      setLoading(true);
      await fetchController(
        baseUrl +
          `/api/v1/assistant/${assistantToEdit?.id}/${toolId}/LinkAssistantTool/${linkAssistantToolId}`,
        "DELETE"
      );
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleToolChange = (selectedOption) => {
    setValue("tool", selectedOption || null, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("toolUser", null);
    handleToolUserPreSelect();
  };

  const handleToolUser = async (selectedOption, context) => {
    setValue("toolUser", selectedOption || null);

    if (context.action === "select-option") {
      await addToolUser(
        context.option.tool_id,
        context.option?.org_id,
        context.option?.id
      );
      await handleToolUserPreSelect();
      loadAssistantTool();
    }
    if (context.action === "remove-value") {
      await removeToolUser(
        context.removedValue?.tool_id,
        context.removedValue?.linkAssistantTool
      );
      await handleToolUserPreSelect();
      loadAssistantTool();
    }
    await updateUseTools((selectedOption || []).length > 0);
  };

  const updateUseTools = async (use_tools = false) => {
    return fetchController(
      baseUrl +
        `/api/v1/assistant/${assistantToEdit.id}`,
      "PUT",
      { use_tools }
    );
  };

  const handleToolIconBtnDelete = async (e, toolId, linkAssistantToolId) => {
    e.stopPropagation();
    await removeToolUser(toolId, linkAssistantToolId);
    await handleToolUserPreSelect();
    loadAssistantTool();
  };

  return (
    <>
      <Modal
        size="xl"
        isOpen={isOpen}
        onClose={() => {
          setValue("name", "");
          handleClose();
        }}
      >
        <ModalOverlay />
        <ModalContent width="100%" maxWidth="1200px">
          <ModalHeader>
            <Flex gap={4}>
              <Text w={"50%"}>{"Update Tool"}</Text>
              <Text
                w={"50%"}
              >{`Connected Tools (${linkAssistantTools?.length})`}</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Stack direction={"row"} gap={4}>
              <Flex direction={"column"} gap={4} w={"50%"}>
                <Box>
                  <FormLabel htmlFor="tool">
                    Tools available to Assistant
                  </FormLabel>
                  <Select
                    placeholder="Select Tool"
                    value={watch("tool")?.value ? watch("tool") : ""}
                    options={toolOptions}
                    name="tool"
                    onChange={handleToolChange}
                  />
                </Box>

                {watch("tool")?.value && (
                  <>
                    <Box>
                      <FormLabel htmlFor="toolUser">
                        Select Configuration
                      </FormLabel>
                      <Select
                        placeholder="Select Configuration"
                        value={watch("toolUser")}
                        options={toolUserOptions}
                        onChange={handleToolUser}
                        isMulti
                        isLoading={loading}
                        isClearable={false}
                      />
                    </Box>
                    <Link to={"/tools"}>
                      {"Go to Tools section to set & configure a Tool"}
                    </Link>
                  </>
                )}

                {watch("toolUser")?.length > 0 && (
                  <Stack>
                    <Flex justifyContent={"space-between"}>
                      <Box>
                        <FormLabel htmlFor="toolUser">
                          Functions available
                        </FormLabel>
                      </Box>
                      <Box>
                        <Text as="b">{toolFunctionCount}</Text>
                      </Box>
                    </Flex>
                    <Text>
                      {
                        "Assistant will invoke tool functions based on their description and its reference in the prompt"
                      }
                    </Text>
                  </Stack>
                )}
              </Flex>

              <Flex w={"50%"} pl={2} maxHeight="600px" overflowY={"auto"}>
                <Skeleton isLoaded={!loading} width={"100%"}>
                  {Object.keys(connectedTools)?.length ? (
                    <Accordion defaultIndex={[0]} allowMultiple width={"100%"}>
                      {Object.keys(connectedTools)?.map((toolId) => (
                        <AccordionItem key={toolId}>
                          <h4>
                            <AccordionButton>
                              {connectedTools[toolId][0]?.tool?.logoUrl ? (
                                <img
                                  src={connectedTools[toolId][0]?.tool?.logoUrl}
                                  alt="Logo"
                                  style={{ width: "40px", height: "40px" }}
                                />
                              ) : (
                                <LiaToolsSolid size={40} />
                              )}

                              <Box as="span" flex="1" textAlign="left">
                                {`${connectedTools[toolId][0].tool.name} (${connectedTools[toolId].length})`}
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h4>
                          <AccordionPanel pb={0}>
                            <Accordion
                              defaultIndex={[0]}
                              allowMultiple
                              width={"100%"}
                            >
                              {connectedTools[toolId].map((toolData) => (
                                <AccordionItem
                                  key={toolData?.toolUserObj?.id}
                                  maxHeight={"200px"}
                                  overflowY={"auto"}
                                >
                                  {({ isExpanded }) => (
                                    <>
                                      <h6>
                                        <AccordionButton>
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                          >
                                            {`${toolData.toolUserObj?.name} (${toolData.toolFunction?.length})`}
                                            <IconButton
                                              variant="outline"
                                              size="xs"
                                              icon={<DeleteIcon />}
                                              colorScheme="red"
                                              ml={2}
                                              title="Delete"
                                              onClick={(e) =>
                                                handleToolIconBtnDelete(
                                                  e,
                                                  toolId,
                                                  toolData.linkAssistantToolId
                                                )
                                              }
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </Box>
                                          {isExpanded ? (
                                            <MinusIcon fontSize="12px" />
                                          ) : (
                                            <AddIcon fontSize="12px" />
                                          )}
                                        </AccordionButton>
                                      </h6>
                                      {toolData?.toolFunction?.map(
                                        (functionData) => (
                                          <AccordionPanel
                                            key={functionData.id}
                                            pb={4}
                                          >
                                            <Flex
                                              justifyContent={"space-between"}
                                            >
                                              <Text>{`${functionData.name}`}</Text>
                                              <Text
                                                title={
                                                  functionData?.details
                                                    ?.openai_spec?.function
                                                    ?.description
                                                }
                                                margin={0}
                                              >
                                                {clipText(
                                                  functionData?.details
                                                    ?.openai_spec?.function
                                                    ?.description || "",
                                                  20
                                                )}
                                              </Text>
                                            </Flex>
                                          </AccordionPanel>
                                        )
                                      )}
                                    </>
                                  )}
                                </AccordionItem>
                              ))}
                            </Accordion>
                          </AccordionPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  ) : (
                    <Text>{"Tool functions not available"}</Text>
                  )}
                </Skeleton>
              </Flex>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddUseTool;
