import { useContext, useEffect, useRef, useState } from "react";
import { SlCallIn, SlCallOut } from "react-icons/sl";
import { LuContact } from "react-icons/lu";

import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Grid,
  GridItem,
  Skeleton,
  Spacer,
  Spinner,
  Stack,
  Tag,
  Text,
  theme,
  useColorModeValue,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";

import dateFormat from "../../utils/DateFormat/dateFormat";
import { fetchController } from "../../utils/FetchController/fetchController";

import { FaFileDownload, FaMicrophone } from "react-icons/fa";
import { FiMessageSquare } from "react-icons/fi";
import { PiRobotLight } from "react-icons/pi";
import { TbDeviceDesktop } from "react-icons/tb";
import Message from "./Message";

import moment from "moment";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import ConfirmationModal from "../Modals/Confirmation";
import { toast } from "react-toastify";
import axios from "axios";
import { exportData } from "../../utils/utilFunc";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import CustomSelect from "../../utils/CustomSelect/CustomSelect";
import { getHostDomain } from "../../utils/utils";
import CustomDateRangePicker from "../../utils/CustomDateRange/CustomDateRangePicker";

const ConversationsUtility = ({ tab, screenType, isActive }) => {
  const baseUrl = getHostDomain();
  const skeletonMap = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

  const [searchParams, setSearchParams] = useSearchParams();

  let widget = searchParams?.get("widget");
  let intent = searchParams?.get("intent");
  let urldateRange = searchParams?.get("dateRange");
  let convId = searchParams?.get("convId");
  let voiceOnly = searchParams?.get("voiceOnly");
  const navigate = useNavigate();
  const location = useLocation();
  const [conversationLoading, setconversationLoading] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [converSationdata, setconverSationdata] = useState([]);
  const [hasMore, sethasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [messages, setMessages] = useState([]);
  const [selectedChat, setSelectedChat] = useState(convId ? convId : "");

  const [deleteChatId, setDeleteChatId] = useState(null);
  const [deleteLoader, setDeleteLoader] = useState(false);

  const [deleteCheckedItems, setDeleteCheckedItems] = useState([]);
  const [deleteAllChecked, setDeleteAllChecked] = useState(false);
  const [isDeleteIndeterminate, setIsDeleteIndeterminate] = useState(false);
  const [deleteCheckedConversationLoader, setDeleteCheckedConversationLoader] =
    useState(false);

  useEffect(() => {
    let allChecked = false;
    if (converSationdata.length && deleteCheckedItems.length) {
      allChecked =
        converSationdata.length === deleteCheckedItems.length ? true : false;
      setDeleteAllChecked(allChecked);
    }
    const isIndeterminate = !allChecked && deleteCheckedItems.length;
    setIsDeleteIndeterminate(isIndeterminate);
  }, [deleteCheckedItems, converSationdata]);

  const [selectionAssistant, setselectionAssistant] = useState(
    widget ? JSON.parse(widget) : ""
  );
  const [assistantOption, setassistantOption] = useState([]);
  const [selectionIntent, setselectionIntent] = useState(
    intent ? JSON.parse(intent) : ""
  );
  const [audioChecked, setaudioChecked] = useState(
    voiceOnly
      ? searchParams.get("voiceOnly") === "true"
        ? true
        : false
      : false
  );
  const [intentOption, setintentOption] = useState([]);

  const [dateRange, setDateRange] = useState(
    urldateRange
      ? {
          startDate: new Date(decodeURIComponent(urldateRange).split(`,`)[0]),
          endDate: new Date(decodeURIComponent(urldateRange).split(`,`)[1]),
        }
      : {
          startDate: moment().subtract(7, "d").toDate(),
          endDate: moment().toDate(),
        }
  );

  const handleDateChange = (range) => {
    setconverSationdata([]);
    setMessages([]);
    sethasMore(true);
    setPage(1);
    setDateRange(
      range
        ? {
            startDate: range.startDate,
            endDate: range.endDate,
          }
        : dateRange
    );

    setSearchParams({
      widget: selectionAssistant ? JSON.stringify(selectionAssistant) : "",
      intent: selectionIntent ? JSON.stringify(selectionIntent) : "",
      voiceOnly: audioChecked,
      dateRange: encodeURIComponent([range.startDate, range.endDate]),
    });
  };
  const elementRef = useRef(null);
  const endpointassistant = "/api/v1/assistant/list";
  const endpointIntent = "/api/v1/intent/list";
  const endpointConversation =
    "/api/v1/analytics/coversation_assistant_daterange";
  const urlHitAssistant = baseUrl + endpointassistant;
  const urlHitIntent = baseUrl + endpointIntent;
  const urlHitConversation = baseUrl + endpointConversation;

  const handleConversation = async (data) => {
    setSelectedChat("");
    setMessages([]);

    if (data) {
      if (!isActive) {
        const params = new URLSearchParams(searchParams);
        params.delete("convId");
        params.delete("widget");
        params.delete("intent");
        params.delete("voiceOnly");
        params.delete("dateRange");
      } else {
        // handleConversationapiCall(data);
        setSearchParams({
          convId: data,
          widget: selectionAssistant ? JSON.stringify(selectionAssistant) : "",
          intent: selectionIntent ? JSON.stringify(selectionIntent) : "",
          voiceOnly: audioChecked,
          dateRange: encodeURIComponent([
            dateRange.startDate,
            dateRange.endDate,
          ]),
        });
      }
      handleConversationapiCall(data);
    }
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "42px",
    }),
  };
  const handleConversationapiCall = async (data) => {
    setSelectedChat(data);
    setChatLoading(false);
    const getConversation = await fetchController(
      baseUrl +
        `/api/v1/conversation/conversationchat_daterange?conversation_id=${data}&date_from=${dateFormat(
          dateRange.startDate
        )}&date_to=${dateFormat(dateRange.endDate)}`,
      "GET"
    );

    if (getConversation.data) {
      setChatLoading(true);
      setMessages(getConversation.data);
    } else {
      setChatLoading(true);
    }
  };

  const handleAssistantList = async () => {
    const getData = await fetchController(urlHitAssistant, "GET");
    setassistantOption(
      getData?.data?.items?.map((data) => {
        return {
          label: data.name,
          value: data.id,
        };
      })
    );
  };
  const handleIntentList = async () => {
    const getData = await fetchController(urlHitIntent, "GET");
    setintentOption(
      getData?.data?.items?.map((data) => {
        return {
          label: data.name,
          value: data.id,
        };
      })
    );
    if (screenType === "intent") {
      setselectionIntent({
        label: getData?.data?.items[0].name,
        value: getData?.data?.items[0].id,
      });
    }
  };

  const handleAssistantSelect = (value) => {
    console.log({ value });
    setconverSationdata([]);
    setMessages([]);
    sethasMore(true);
    setPage(1);
    setselectionAssistant(value);
    setSearchParams({
      widget: JSON.stringify(value),
      intent: selectionIntent ? JSON.stringify(selectionIntent) : "",
      voiceOnly: audioChecked,
      dateRange: encodeURIComponent([dateRange.startDate, dateRange.endDate]),
    });
  };
  const handleIntentSelect = (value) => {
    setconverSationdata([]);
    setMessages([]);
    sethasMore(true);
    setPage(1);
    setselectionIntent(value);
    setSearchParams({
      widget: selectionAssistant ? JSON.stringify(selectionAssistant) : "",
      intent: JSON.stringify(value),
      voiceOnly: audioChecked,
      dateRange: encodeURIComponent([dateRange.startDate, dateRange.endDate]),
    });
  };

  const handleCheckboxSelect = (value) => {
    setconverSationdata([]);
    setMessages([]);
    sethasMore(true);
    setPage(1);

    setaudioChecked(value.target.checked);
    setSearchParams({
      widget: selectionAssistant ? JSON.stringify(selectionAssistant) : "",
      intent: selectionIntent ? JSON.stringify(selectionIntent) : "",
      voiceOnly: value.target.checked,
      dateRange: encodeURIComponent([dateRange.startDate, dateRange.endDate]),
    });
  };

  useEffect(() => {
    // if ((dateRange && selectionAssistant) || selectionIntent || selectionAssistant ) {
    if (!converSationdata) {
      setconversationLoading(false);
    }
    handleConversationCall();

    //  }
  }, [dateRange, selectionAssistant, selectionIntent, audioChecked]);

  const onIntersection = (entries) => {
    const firstEntry = entries[0];
    if (firstEntry.isIntersecting && hasMore) {
      handleConversationCall();
    }
  };
  useEffect(() => {
    const observer = new IntersectionObserver(onIntersection);
    if (observer && elementRef.current) {
      observer.observe(elementRef.current);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [converSationdata]);

  const handleConversationCall = async () => {
    try {
      let fullUrl;
      fullUrl = `/api/v1/conversation/conversation_assistant_daterange?${
        selectionAssistant?.value
          ? "assistant_id=" + selectionAssistant?.value + "&"
          : ""
      }${
        selectionIntent?.value
          ? "intent_id=" + selectionIntent?.value + "&"
          : ""
      }date_from=${dateFormat(dateRange.startDate)}&date_to=${dateFormat(
        dateRange.endDate
      )}&includes_voice=${audioChecked}&page=${page}&size=50`;
      const getData = await fetchController(baseUrl + `${fullUrl}`, "GET");
      if (getData) {
        setconversationLoading(true);
        setChatLoading(true);
      }
      if (getData.data.items.length == 0) {
        sethasMore(false);
      }
      let chatId = searchParams?.get("convId");
      if (chatId && getData.data.items.length) {
        handleConversation(chatId);
      } else if (!chatId && getData.data.items.length && page === 1) {
        handleConversation(getData.data.items[0].id);
      }
      setconverSationdata((prevProduct) => [
        ...prevProduct,
        ...getData?.data.items,
      ]);
      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      setconversationLoading(true);
      setChatLoading(true);
    }
  };

  const {
    isOpen: isConfirmationModalOpen,
    onOpen: onOpenConfirmationModal,
    onClose: onCloseConfirmationModal,
  } = useDisclosure();

  const {
    isOpen: isCheckedConfirmationModalOpen,
    onOpen: onOpenCheckedConfirmationModal,
    onClose: onCloseCheckedConfirmationModal,
  } = useDisclosure();

  const refreshConversation = () => {
    setconverSationdata([]);
    setMessages([]);
    setPage(1);
    setselectionAssistant("");
    setselectionIntent("");
    setDateRange({
      startDate: moment().subtract(7, "d").toDate(),
      endDate: moment().toDate(),
    });
    setaudioChecked(false);
  };

  const onConfirmDelete = async () => {
    if (!deleteChatId) return;
    setDeleteLoader(true);
    try {
      onCloseConfirmationModal();
      const url = baseUrl + `/api/v1/conversation/${deleteChatId}`;
      const response = await fetchController(url, "DELETE");
      setDeleteChatId(null);
      setDeleteLoader(false);
      toast.success(response.detail || "conversation deleted successfully");
      refreshConversation();
    } catch (error) {
      onCloseConfirmationModal();
      setDeleteChatId(null);
      setDeleteLoader(false);
      toast.error(
        "Conversation has other linked items. Please delete them first and then retry."
      );
    } finally {
      setDeleteChatId(null);
      setDeleteLoader(false);
      onCloseConfirmationModal();
    }
  };

  const onCheckedConfirmDelete = async () => {
    setDeleteCheckedConversationLoader(true);
    onCloseCheckedConfirmationModal();
    try {
      const url = baseUrl + `/api/v1/conversation/`;
      const body = [...deleteCheckedItems];
      const response = await fetchController(url, "DELETE", body);
      setDeleteAllChecked(false);
      setDeleteCheckedItems([]);
      setDeleteCheckedConversationLoader(false);
      toast.success(response.detail || "conversations deleted successfully");
      refreshConversation();
    } catch (error) {
      toast.error(
        "Conversation has other linked items. Please delete them first and then retry."
      );
    } finally {
      setDeleteCheckedConversationLoader(false);
    }
  };
  const downloadCSV = async () => {
    if (!selectionAssistant || selectionAssistant === null) {
      toast.error("Select Assistant");
      return;
    }
    axios
      .get(
        baseUrl +
          `/api/v1/conversation/export/csv?assistant_id=${
            selectionAssistant.value
          }&date_from=${dateFormat(dateRange.startDate)}&date_to=${dateFormat(
            dateRange.endDate
          )}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "text/csv;charset=utf-8;",
          },
        }
      )
      .then((response) => {
        exportData(response.data, "data.csv", "text/csv;charset=utf-8;");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    handleAssistantList();
    handleIntentList();
  }, []);
  const agency = useContext(AgencyContext);
  const { textColor, colorPreset, buttonColorScheme } = agency;
  const chatBg = useColorModeValue("#E8E8E8", theme.colors.gray[700]);
  const chatColor = useColorModeValue("black", "white");
  const selectedChatBg = useColorModeValue(colorPreset || "#3182ce", textColor);

  return (
    <>
      <ConfirmationModal
        table={"conversation"}
        isOpen={isConfirmationModalOpen}
        onConfirm={onConfirmDelete}
        onClose={onCloseConfirmationModal}
      />
      <ConfirmationModal
        table={"checkedConversationDelete"}
        isOpen={isCheckedConfirmationModalOpen}
        onConfirm={onCheckedConfirmDelete}
        onClose={onCloseCheckedConfirmationModal}
      />
      <Box w={{ base: "75%" }} m={"0px auto"}>
        <Grid templateColumns="repeat(7, 1fr)" gap={5} mb={"10px"}>
          <GridItem colSpan={1}>
            <Box
              display={"flex"}
              alignItems={"center"}
              padding={"5px"}
              justifyContent={"center"}
            >
              <CheckboxGroup>
                <Stack spacing={[1, 5]} direction={["column", "row"]}>
                  <Checkbox
                    isChecked={audioChecked}
                    onChange={handleCheckboxSelect}
                  >
                    Voice Only{audioChecked}
                  </Checkbox>
                </Stack>
              </CheckboxGroup>
            </Box>
          </GridItem>
          <GridItem colSpan={2}>
            <CustomDateRangePicker
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              onChange={handleDateChange}
            />
          </GridItem>
          <GridItem colSpan={2}>
            {/* <Select
              value={selectionAssistant}
              onChange={handleAssistantSelect}
              options={assistantOption}
              placeholder="Filter by Assistant"
              isClearable
              styles={customStyles}
            /> */}
            <CustomSelect
              placeholder="Filter by Assistant"
              value={selectionAssistant?.value}
              onChange={(val) => {
                handleAssistantSelect({
                  value: val?.value ? val?.value : null,
                  label: val?.label ? val?.label : null,
                });
              }}
              options={assistantOption?.map((val) => {
                return { value: val.value, label: val.label };
              })}
            />
          </GridItem>
          <GridItem colSpan={2}>
            {/* <Select
              value={selectionIntent}
              onChange={handleIntentSelect}
              options={intentOption}
              placeholder="Filter by Intent"
              isClearable
              styles={customStyles}
            /> */}

            <CustomSelect
              placeholder="Filter by Intent"
              value={selectionIntent?.value}
              onChange={(val) => {
                handleAssistantSelect({
                  value: val?.value ? val?.value : null,
                  label: val?.label ? val?.label : null,
                });
              }}
              options={intentOption?.map((val) => {
                return { value: val.value, label: val.label };
              })}
            />
          </GridItem>
        </Grid>
      </Box>

      <Box
        paddingBlock={3}
        // bg={"white"}
        margin={"0px auto"}
        borderRadius="lg"
        borderWidth="1px"
        w={{ base: "75%" }}
      >
        {converSationdata.length !== 0 && (
          <Box borderBottom={"1px"} mb={4} pb={2}>
            <Flex
              justifyContent={"flex-start"}
              alignItems={"center"}
              pl={3}
              pr={3}
            >
              <Box
                display={"flex"}
                alignItems={"Center"}
                padding={"5px"}
                justifyContent={"center"}
              >
                <CheckboxGroup>
                  <Stack spacing={[1, 5]} direction={["column", "row"]}>
                    <Checkbox
                      isChecked={deleteAllChecked}
                      isIndeterminate={isDeleteIndeterminate}
                      onChange={() => {
                        if (deleteAllChecked) {
                          setDeleteAllChecked(false);
                          setDeleteCheckedItems([]);
                        } else {
                          setDeleteCheckedItems(() =>
                            converSationdata.map((messages) => messages.id)
                          );
                        }
                      }}
                      fontWeight={600}
                    >
                      {"Select All"}
                    </Checkbox>
                  </Stack>
                </CheckboxGroup>
              </Box>
              <Box p={2}>
                {deleteCheckedConversationLoader ? (
                  <Spinner size="md" />
                ) : (
                  <Box as="span" title="Delete conversations">
                    <MdDelete
                      color={deleteCheckedItems.length ? "red" : "lightgray"}
                      cursor={
                        deleteCheckedItems.length ? "pointer" : "not-allowed"
                      }
                      size={"1.2rem"}
                      onClick={() => {
                        onOpenCheckedConfirmationModal();
                      }}
                    />
                  </Box>
                )}
              </Box>
              <Button
                type="button"
                colorScheme={buttonColorScheme}
                onClick={downloadCSV}
              >
                <FaFileDownload />
              </Button>
            </Flex>
          </Box>
        )}

        <Box display={"flex"} flexDir="unset" justifyContent="center">
          <Box
            d="flex"
            w="50%"
            justifyContent="space-between"
            alignItems="center"
            height="65vh"
            overflowY="auto"
            pl={3}
            pr={3}
          >
            <Stack spacing="1">
              {conversationLoading ? (
                <>
                  {converSationdata?.length ? (
                    <>
                      {converSationdata?.map((message) => {
                        let attriButes = JSON.parse(message?.attributes);
                        return (
                          <Flex key={message?.id}>
                            <Box
                              display={"flex"}
                              alignItems={"Center"}
                              padding={"5px"}
                              justifyContent={"center"}
                            >
                              <CheckboxGroup>
                                <Stack
                                  spacing={[1, 5]}
                                  direction={["column", "row"]}
                                >
                                  <Checkbox
                                    isChecked={deleteCheckedItems.includes(
                                      message.id
                                    )}
                                    onChange={() => {
                                      if (
                                        deleteCheckedItems.includes(message.id)
                                      ) {
                                        setDeleteCheckedItems((prevState) =>
                                          prevState.filter(
                                            (item) => item !== message.id
                                          )
                                        );
                                      } else {
                                        setDeleteCheckedItems((prevState) => [
                                          ...prevState,
                                          message.id,
                                        ]);
                                      }
                                    }}
                                  ></Checkbox>
                                </Stack>
                              </CheckboxGroup>
                            </Box>

                            <Box
                              // bg={
                              //   selectedChat === message?.id
                              //     ? "#3182ce"
                              //     : "#E8E8E8"
                              // }
                              bg={
                                selectedChat === message?.id
                                  ? selectedChatBg
                                  : chatBg
                              }
                              // color={
                              //   selectedChat === message?.id ? "white" : "black"
                              // }
                              color={
                                selectedChat === message?.id
                                  ? "white"
                                  : chatColor
                              }
                              px={3}
                              py={2}
                              padding
                              borderRadius="5px"
                              key={message.id}
                              flex={1}
                              cursor="pointer"
                              onClick={() => {
                                handleConversation(message.id);
                              }}
                            >
                              <Box>
                                <Box
                                  display={"Flex"}
                                  justifyContent={"space-between"}
                                >
                                  {" "}
                                  <Box
                                    display={"flex"}
                                    alignItems={"center"}
                                    gap={1}
                                  >
                                    <PiRobotLight width={20} height={20} />
                                    <Text fontSize="xs">
                                      {message?.assistant_name}
                                    </Text>
                                  </Box>
                                  <Text fontSize="xs" marginTop={"unset"}>
                                    {moment.utc(message?.updated_at).fromNow()}
                                  </Text>
                                </Box>
                                <Box
                                  display={"Flex"}
                                  justifyContent={"space-between"}
                                  mt={"2px"}
                                  mb={"2px"}
                                >
                                  <Text
                                    fontSize="xs"
                                    display={"flex"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                  >
                                    {message?.first_message}
                                  </Text>
                                  <Box>
                                    {message?.intent_name && (
                                      <Tag>{message?.intent_name}</Tag>
                                    )}
                                  </Box>
                                </Box>
                              </Box>

                              <Box
                                display={"Flex"}
                                justifyContent={"space-between"}
                              >
                                <Box
                                  display={"flex"}
                                  alignItems={"center"}
                                  gap={1}
                                >
                                  {message.contact_first_name ||
                                  message.contact_last_name ? (
                                    <LuContact />
                                  ) : null}
                                  <Tooltip
                                    label={
                                      attriButes?.call_direction === "inbound"
                                        ? attriButes?.from
                                        : attriButes?.to
                                    }
                                  >
                                    <Text
                                      fontWeight={"bold"}
                                      as="i"
                                      fontSize={"10px"}
                                    >
                                      {`${message?.contact_first_name || ""} ${
                                        message?.contact_last_name || ""
                                      }`}
                                    </Text>
                                  </Tooltip>
                                </Box>
                                <Box
                                  display={"flex"}
                                  alignItems={"center"}
                                  gap={1}
                                >
                                  <Flex justifyContent="end" p={"0.3rem"}>
                                    <>
                                      {deleteChatId === message.id &&
                                      deleteLoader ? (
                                        <Spinner size="sm" />
                                      ) : (
                                        <Box
                                          as="span"
                                          title="Delete conversation"
                                        >
                                          <MdDelete
                                            color={
                                              deleteCheckedItems.includes(
                                                message.id
                                              ) ||
                                              deleteCheckedConversationLoader
                                                ? "lightgray"
                                                : "red"
                                            }
                                            cursor={
                                              deleteCheckedItems.includes(
                                                message.id
                                              ) ||
                                              deleteCheckedConversationLoader
                                                ? "not-allowed"
                                                : "pointer"
                                            }
                                            size={"1rem"}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              if (
                                                deleteCheckedItems.includes(
                                                  message.id
                                                ) ||
                                                deleteCheckedConversationLoader
                                              )
                                                return;
                                              setDeleteChatId(message.id);
                                              onOpenConfirmationModal();
                                            }}
                                          />
                                        </Box>
                                      )}
                                    </>
                                  </Flex>
                                  {message?.includes_voice ? (
                                    <FaMicrophone />
                                  ) : (
                                    ""
                                  )}
                                  {message.device_type === "phone" &&
                                  attriButes.call_direction === "inbound" ? (
                                    <SlCallIn
                                      title={
                                        `From: ${attriButes?.from_formatted}` ||
                                        "Phone number N/A"
                                      }
                                    />
                                  ) : message.device_type === "phone" &&
                                    attriButes.call_direction === "outbound" ? (
                                    <SlCallOut
                                      title={
                                        `To: ${attriButes?.to_formatted}` ||
                                        "Phone number N/A"
                                      }
                                    />
                                  ) : null}

                                  {message.device_type === "desktop" ? (
                                    <TbDeviceDesktop />
                                  ) : null}
                                  <Box
                                    display={"flex"}
                                    alignItems={"center"}
                                    gap={1}
                                  >
                                    <FiMessageSquare width={20} height={20} />

                                    <Text fontSize="xs">
                                      {" "}
                                      {message?.chat_count}
                                    </Text>
                                  </Box>
                                </Box>
                              </Box>
                              {/* {hasMore && <Box ref={elementRef}> </Box>} */}
                            </Box>
                          </Flex>
                        );
                      })}
                    </>
                  ) : (
                    <Box
                      // color={"black"}
                      px={3}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      py={2}
                      padding
                      borderRadius="5px"
                    >
                      No conversation
                    </Box>
                  )}
                </>
              ) : (
                <>
                  {skeletonMap.map((item) => {
                    return (
                      <>
                        <Skeleton
                          startColor="#3182ce6e"
                          endColor="##3182ce6e"
                          height="36px"
                          key={`${item}`}
                        ></Skeleton>
                      </>
                    );
                  })}
                </>
              )}
            </Stack>
          </Box>
          <Spacer />
          <Box
            pl={3}
            pr={3}
            d="flex"
            w="50%"
            height="65vh"
            overflowY="auto"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box className="parent" id="parentDiv">
              <>
                {chatLoading ? (
                  <>
                    {messages.length ? (
                      <>
                        {messages?.map((messageData, index) => {
                          return (
                            <>
                              <Message
                                messageData={messageData?.ConversationChat}
                                key={messageData.id}
                              />
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <Box
                        // color={"black"}
                        px={3}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        py={2}
                        padding
                        borderRadius="5px"
                      >
                        No chat{" "}
                      </Box>
                    )}
                  </>
                ) : (
                  <Box display={"flex"} flexDirection={"column"} spacing={"2"}>
                    <Stack spacing="1">
                      {skeletonMap.map((item) => {
                        return (
                          <>
                            <Skeleton
                              startColor="#3182ce6e"
                              endColor="##3182ce6e"
                              height="36px"
                              bg="green.500"
                              color="white"
                              key={`${item}`}
                            ></Skeleton>
                          </>
                        );
                      })}
                    </Stack>
                  </Box>
                )}
              </>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default ConversationsUtility;
