import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Stack,
  Switch,
  Textarea,
  theme,
  useColorModeValue,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import Select from "react-select";
import WebhookSelect from "./WebhookSelect";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { useContext } from "react";

export default function DetailsForm({ formDetailsForm, attributesForm }) {
  const { register, control, watch, setValue, setError } = formDetailsForm;
  const typeOfForms = [
    { label: "Simple form", value: "simple" },
    { label: "Natural form", value: "natural" },
  ];
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, cardBg: bg } = agency;
  return (
    <Box gap={2} backgroundColor={bg} borderRadius={"md"} padding={3}>
      <Stack spacing={2}>
        <Grid gap={3} gridTemplateColumns={"1fr 1fr"} spacing={3}>
          <FormControl isRequired>
            <FormLabel>Type of Form</FormLabel>
            <Controller
              name="type"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <Select
                  options={typeOfForms}
                  value={typeOfForms.find((c) => c.value === value)}
                  onChange={(val) => onChange(val.value)}
                />
              )}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              placeholder="Name"
              value={watch("name")}
              onChange={(e) => setValue("name", e.currentTarget.value)}
              name="name"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Trigger Instructions</FormLabel>
            <Textarea
              defaultValue={watch("triggerInstructions")}
              {...register("triggerInstructions")}
              name="triggerInstructions"
            />
          </FormControl>
          <FormControl>
            <FormLabel>Webhook</FormLabel>
            <WebhookSelect
              webhook_id={watch("webhook_id")}
              onChangeWebhook={(value) =>
                value
                  ? formDetailsForm.setValue("webhook_id", value.value)
                  : formDetailsForm.setValue("webhook_id", null)
              }
            />
            <Link to={"/settings/webhooks"}>Create Webhook</Link>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Label for Submit</FormLabel>
            <Input
              defaultValue={watch("submitLabel")}
              {...register("submitLabel")}
              name="submitLabel"
            />
          </FormControl>
          <FormControl
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <FormLabel>Is submission mandatory ?</FormLabel>
            <Switch
              isChecked={attributesForm.watch("mandatory_submission")}
              onChange={(e) => {
                attributesForm.setValue(
                  "mandatory_submission",
                  e.currentTarget.checked
                );
              }}
            />
          </FormControl>
        </Grid>
      </Stack>
    </Box>
  );
}
