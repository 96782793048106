import moment from "moment";

export function formatDateWithAmPm(timestamp) {
  if (!timestamp) return "-";

  // Parse the timestamp as UTC using Moment.js
  const date = moment.utc(timestamp);

  // Convert to browser's timezone
  const browserTimezoneDate = date.clone().local();

  // Format the date/time in the browser's timezone
  const formattedDate = browserTimezoneDate.format("MMMM Do, h:mm a");

  return formattedDate;
}

