import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Tr,
} from "@chakra-ui/react";
import { IoEllipsisVerticalSharp, IoEyeOutline } from "react-icons/io5";
import { MdCallReceived, MdDelete } from "react-icons/md";
import { RxComponentPlaceholder } from "react-icons/rx";

export default function LogRow(props) {
  return (
    <Tr>
      <Td>{props.log.tool_user_id}</Td>
      <Td>{props.log.tool_function_id}</Td>
      <Td>{props.log.widget_id}</Td>
      <Td>
        <Menu>
          <MenuButton
            colorScheme="blue"
            size={"sm"}
            as={IconButton}
            aria-label="Options"
            icon={<IoEllipsisVerticalSharp />}
            variant="outline"
          />
          <MenuList>
            <MenuItem
              onClick={() => props.onOpenShowModal(props.log)}
              icon={<IoEyeOutline />}
            >
              Show
            </MenuItem>
            <MenuItem
              onClick={() => {
                const json = JSON.parse(props.log.call_response);
                props.onOpenCallDetailsModal({
                  json,
                  title: "Call response",
                });
              }}
              icon={<MdCallReceived />}
            >
              Call response
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.onOpenCallDetailsModal({
                  json: props.log.call_arguments,
                  title: "Call arguments",
                });
              }}
              icon={<RxComponentPlaceholder />}
            >
              Arguments
            </MenuItem>
            <MenuItem
              onClick={() => props.onDeleteLog(props.log)}
              icon={<MdDelete />}
            >
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}
