import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Link,
  Select,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { countryList } from "../../utils/CountryList/CountryList";
export default function CallVisualRepresentation({ widgetFields }) {
  const fieldsMap = {
    text: Input,
    checkbox: Checkbox,
    textarea: Textarea,
    gdpr: Checkbox,
  };
  return (
    <Box>
      <Flex p={3} justifyContent={"center"} alignItems={"center"}>
        <Image
          src={widgetFields.logo}
          fallbackSrc={"/logo/favicon.png"}
          width={30}
        />
        <Heading>{widgetFields.header_name}</Heading>
      </Flex>
      <Stack p={3} spacing={3}>
        {widgetFields.fields.map((field, index) => {
          const Field = fieldsMap[field.type] || Input;
          return field.type === "tel" ? (
            <FormControl isRequired={field.required}>
              <FormLabel>{field.label}</FormLabel>
              <Flex>
                <Box maxW={150}>
                  <Select defaultValue={field.default_country_code}>
                    {countryList.map((countryCode, index) => (
                      <option key={index}>
                        +{countryCode.dial_code}({countryCode.code})
                      </option>
                    ))}
                  </Select>
                </Box>
                <Input {...field} />
              </Flex>
            </FormControl>
          ) : field.type === "checkbox" || field.type === "gdpr" ? (
            <Flex
              gap={3}
              justifyContent={"flex-start"}
              alignItems={"center"}
              key={index}
            >
              <Field {...field} key={index}>
                {field.label}
              </Field>
              {field.type === "gdpr" ? (
                <Link href={field.privacy_consent_url}>
                  {field.description}
                </Link>
              ) : null}
            </Flex>
          ) : (
            <FormControl isRequired={field.required} key={index}>
              <FormLabel>{field.label}</FormLabel>
              {field.type === "select" ? (
                <Select>
                  {field.options.map((field, index) => (
                    <option key={index}>{field}</option>
                  ))}
                </Select>
              ) : (
                <Field {...field} />
              )}
            </FormControl>
          );
        })}
        <Button
          backgroundColor={"3b81f6"}
          margin={"auto"}
          style={{ ...widgetFields.theme.button }}
        >
          {widgetFields.button_label}
        </Button>
      </Stack>
      {widgetFields.remove_branding ? null : (
        <Text textAlign={"center"}>
          Powered By <strong>Insighto.AI</strong>
        </Text>
      )}
    </Box>
  );
}
