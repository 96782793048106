import { Box } from "@chakra-ui/react";

import SubAccountTable from "../SubAccountTable";

export default function SubAccount() {

    return (
        <Box border="1px"  borderRadius="md" h="81vh" overflowY="auto">
            <SubAccountTable />
        </Box>
    )
}