import { countryList } from "../../utils/CountryList/CountryList";

export default function TelephoneField({ field }) {
  return (
    <label className="text__field field">
      <div className="field__label">
        <span>{field.label}</span>
        {field.required ? <span className="required">*</span> : null}
      </div>
      <div
        style={{
          gridTemplateColumns: field.show_country_code ? "auto 1fr" : "",
        }}
        className="telephone__field text__field"
      >
        {field.show_country_code ? (
          <select onChange={() => {}} value={field.default_country_code}>
            {countryList.map((countryCode, index) => (
              <option
                value={countryCode.dial_code}
                key={index}
              >{`+${countryCode.dial_code} (${countryCode.code})`}</option>
            ))}
          </select>
        ) : null}
        <input type="tel" placeholder={field.description} name={field.name} />
      </div>
    </label>
  );
}
