import { Flex, Image, Text } from "@chakra-ui/react";

export default function Message({
  text,
  sentBy,
  bgColor,
  conversationBotIcon,
  color
}) {
  return (
    <Flex
      gap={2}
      justifyContent={sentBy === "me" ? "flex-end" : "flex-start"}
      alignItems={"center"}
    >
      {sentBy === "me" ? null : (
        <Image
          src={conversationBotIcon || "/logo/favicon.png"}
          alt="Logo"
          flex={"1fr"}
          height={"32px"}
          width={"32px"}
        />
      )}
      <Flex>
        <Flex
          borderRadius={10}
          padding={1}
          color={color}
          justifyContent={sentBy === "me" ? "flex-end" : "flex-start"}
          backgroundColor={bgColor}
          flex={"auto"}
        >
          <Text fontWeight={500} fontSize={"1rem"} padding={"0.5rem"}>
            {text}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
