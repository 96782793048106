import React, { useEffect, useState } from "react";
import { Box, Button, Text, Spinner, Tooltip } from "@chakra-ui/react";
import { FaCheck } from "react-icons/fa6";
import { fetchController } from "../../../utils/FetchController/fetchController";
import Loader from "../../../utils/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { getHostDomain } from "../../../utils/utils";

const BillingCard = ({
  planName,
  features,
  pricing,
  active,
  popular,
  label,
  erlyBirdsOffer,
  isDisable,
  isCoupan,
  isTrial,
  bg,
  color,
  buttonColorScheme,
  lookup_key
}) => {
  const baseUrl = getHostDomain();
  const endpoint = "/api/v1/billing/create-checkout-session";
  const url = baseUrl;
  const urlHit = url + endpoint;
  const [loading, setLoading] = useState(false);
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    return () => setIsFullScreenLoading(false);
  }, []);
  const submit = async (lookup_key, label) => {
    const isConfirmed = window.confirm(
      `Are you sure you want to ${label} plan ?`
    );
    if (!isConfirmed) return;
    setLoading(true);
    try {
      const body = {
        lookup_key: lookup_key,
      };
      const getData = await fetchController(urlHit, "POST", body);
      if (getData.checkout_url) {
        window.location = getData.checkout_url;
      } else {
        setLoading(false);
        setIsFullScreenLoading(true);
        setTimeout(() => {
          window.location.reload();
          setIsFullScreenLoading(false);
        }, 5000);
      }
    } catch (error) {
      setIsFullScreenLoading(false);
      console.error("Error occour while upgrading plan: ", error);
    } finally {
      setLoading(false);
    }
  };

  const formatKey = (key) => {
    // Remove underscores and add space
    const words = key ? key.split("_") : [];
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  };
  const getPlanName=(plan)=>{
    if(!plan) return null
    let nameArr= formatKey(planName).split("-")
    return nameArr[nameArr.length-1]
  }
  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p="4"
      mt={4}
      width={isCoupan ? "100%" : { base: "100%", sm: "50%", xl: "25%" }}
      height={isCoupan ? "340px" : "490px"}
      boxShadow="md"
      bg={bg}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      borderStyle="solid"
      borderColor={active ? color : "transparent"}
      position="relative"
    >
      {isFullScreenLoading && (
        <Loader text="We are changing your plan, please wait…" />
      )}
      {popular && (
        <Box
          color="white"
          fontSize="13px"
          p="1"
          fontWeight="bold"
          position="absolute"
          right="0"
          top="0"
          bg={color}
          fontFamily={popular ? "'Lato', sans-serif" : "'Poppins', sans-serif"}
        >
          Popular
        </Box>
      )}
      <Box mt="3">
        <Box height={isCoupan ? "auto" : "120px"}>
          <Text
            fontSize={isCoupan ? "18px" : "23px"}
            fontWeight="bold"
            textAlign="center"
          >
            {isCoupan ? planName : getPlanName(planName)}
          </Text>

          <Text
            fontSize="22px"
            fontWeight="bold"
            textAlign="center"
            color={color}
          >
            {formatKey(pricing)}
          </Text>

          <Text textAlign="center" fontWeight="bold">
            {erlyBirdsOffer && erlyBirdsOffer}
          </Text>
        </Box>
        <Box mt={0} flex="1" overflowY="auto">
          {features[0] &&
            Object.entries(features[0]).map(([key, value], index) => (
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                mt={1}
                ml={2}
                key={index}
              >
                {value === false ? (
                  <>
                    <FaCheck size={15} color="#8080808a" />
                    <Text color="#8080808a" fontSize="12px" fontWeight="bold">
                      {formatKey(key)}
                    </Text>
                  </>
                ) : (
                  <React.Fragment>
                    <FaCheck size={15} color={color} />
                    <Text fontSize="12px" fontWeight="bold">
                      {formatKey(key)}{" "}
                      {typeof value === "number" && value.toLocaleString()}
                    </Text>
                  </React.Fragment>
                )}
              </Box>
            ))}
        </Box>
      </Box>
      {!active ? (
        <Tooltip
          label={
            isDisable && isTrial
              ? "You are on a trial period. Plan will automatically be downgraded on its completion."
              : ""
          }
          aria-label={
            isDisable && isTrial
              ? "You are on a trial period. Plan will automatically be downgraded on its completion."
              : ""
          }
        >
          <Button
            onClick={() => {
              submit(lookup_key, label);
            }}
            colorScheme={buttonColorScheme}
            mt="4"
            alignSelf="center"
            textTransform={"capitalize"}
            width="120px"
            isDisabled={isDisable}
          >
            {loading ? <Spinner size="xs" /> : label}{" "}
          </Button>
        </Tooltip>
      ) : (
        <Box display="flex" justifyContent="center">
          <Text fontSize="20px" fontWeight="bold" color={color}>
            Activated
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default BillingCard;
