import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  ModalBody,
  ModalFooter,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { IoChevronBackOutline } from "react-icons/io5";
import { MdOutlineDelete } from "react-icons/md";
import Select from "react-select";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import SimpleTokenFields from "./SimpleTokenFields";
import useNewAuthCreation, { authTypeEnum } from "./useNewAuthCreation";
import useQueryParams from "./useQueryParams";
import { useContext, useState } from "react";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { getHostDomain } from "../../utils/utils";

export default function AuthorizationBodyForm({
  tool,
  stepHook,
  toolUserForm,
  externalAuthHook,
}) {
  const baseUrl = getHostDomain();
  const { register, reset, watch, formState, handleSubmit, setValue } =
    toolUserForm;
  const authForm = watch();

  const { isOpen: isKeyFieldsOpen, onToggle: toggleKeyFields } =
    useDisclosure();
  const { getNewAuthFunction } = useNewAuthCreation({
    tool,
    toggleKeyFields,
  });
  const query = useQueryParams();
  const code = query.get("code");
  const onClickNewAuth = getNewAuthFunction();
  const { auths, refreshAuths, status } = externalAuthHook;
  const onHandleSubmit = async (data) => {
    const { body, method, url } = makeRequestForAuth({ data, tool, query });
    try {
      const respone = await fetchController(
        baseUrl + url,
        method,
        body
      );
      setValue("tool_user_id", data.tool_user_id || respone.data.id);
      stepHook.goToNext();
      refreshAuths();
    } catch (error) {
      toast.error("Authentication failed, please try again");
    }
  };
  const [selectedAuthStatus, setSelectedAuthStatus] = useState("idle");
  const onDeleteAuth = async () => {
    try {
      setSelectedAuthStatus("deleting");
      await fetchController(
        baseUrl +
          `/api/v1/tool/ToolUser/${authForm.tool_user_id}`,
        "delete"
      );
      refreshAuths();
      setValue("tool_user_id", undefined);
      toast.success("User deleted");
    } catch (error) {
      toast.error("Some error occured");
    } finally {
      setSelectedAuthStatus("idle");
    }
  };
  const toolUserOptions = auths.map((auth) => ({
    value: auth.id,
    label: auth.name,
  }));
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, colorPreset } = agency;
  return (
    <form onSubmit={handleSubmit(onHandleSubmit)}>
      <ModalBody>
        {status === "loading" ? (
          <Flex justifyContent={"center"} alignItems={"center"}>
            <Spinner />
          </Flex>
        ) : (
          <Stack spacing={2}>
            {code || isKeyFieldsOpen ? null : (
              <FormControl isRequired>
                <FormLabel>Exisiting Configuration</FormLabel>
                <Flex justifyContent={"center"} alignItems={"center"} gap={2}>
                  <Box flex={1}>
                    <Select
                      placeholder="Choose one"
                      required
                      value={toolUserOptions.find(
                        (toolUser) => toolUser.value === authForm.tool_user
                      )}
                      options={toolUserOptions}
                      onChange={({ value, label }) => {
                        reset({
                          tool_user_id: value,
                          name: label,
                        });
                      }}
                    />
                  </Box>
                  {authForm.tool_user_id ? (
                    <IconButton
                      isLoading={selectedAuthStatus === "deleting"}
                      icon={<MdOutlineDelete />}
                      colorScheme="red"
                      onClick={onDeleteAuth}
                    />
                  ) : null}
                  {authForm.tool_user_id || code ? null : <Text>OR</Text>}
                  {code || authForm.tool_user_id ? null : (
                      <Button onClick={onClickNewAuth} colorScheme={buttonColorScheme}>
                      Start new
                    </Button>
                  )}
                </Flex>
              </FormControl>
            )}
            {isKeyFieldsOpen ? (
              <Flex justifyContent={"flex-start"} alignItems={"center"}>
                <IconButton
                  size={"sm"}
                  isRound
                  icon={<IoChevronBackOutline />}
                  onClick={() => {
                    toggleKeyFields();
                  }}
                />
              </Flex>
            ) : null}
            {authForm.tool_user_id || code || isKeyFieldsOpen ? (
              <FormControl isRequired>
                <FormLabel>Name</FormLabel>
                <Input
                  {...register("name", {
                    required: true,
                  })}
                />
              </FormControl>
            ) : null}
            {[authTypeEnum.SIMPLE_TOKEN, authTypeEnum.OAUTH_KEYS].includes(
              tool?.authentication?.method
            ) && isKeyFieldsOpen ? (
              <SimpleTokenFields
                toolUserForm={toolUserForm}
                key_fields={tool?.authentication?.key_fields}
              />
            ) : null}
          </Stack>
        )}
      </ModalBody>

      <ModalFooter>
        <ButtonGroup>
          {authForm.tool_user_id ? (
            <Button
              isLoading={formState.isSubmitting}
              type="submit"
              colorScheme="blue"
            >
              Next
            </Button>
          ) : null}
          {code || isKeyFieldsOpen ? (
            <Button isLoading={formState.isSubmitting} type="submit">
              Create
            </Button>
          ) : null}
        </ButtonGroup>
      </ModalFooter>
    </form>
  );
}
const getAllQueries = (query) => {
  const queries = {};
  for (const [key, value] of query.entries()) {
    if (value) queries[key] = value;
  }
  return queries;
};
const makeRequestForAuth = ({ data, tool, query }) => {
  const queries = getAllQueries(query);
  const makeOauthBody = () => {
    const body = data.tool_user_id
      ? {
          name: data.name,
        }
      : {
          name: data.name,
          tool_id: tool.id,
          credentials: {
            redirect_uri: process.env.REACT_APP_REDIRECT_URI,
            ...queries,
          },
        };
    return body;
  };
  const makeKeysBody = () => {
    const body = data.tool_user_id
      ? {
          name: data.name,
        }
      : {
          name: data.name,
          credentials: data.credentials || {},
          tool_id: tool.id,
        };
    return body;
  };
  const authenticationTypeMap = {
    [authTypeEnum.OAUTH]: makeOauthBody,
    [authTypeEnum.SIMPLE_TOKEN]: makeKeysBody,
    [authTypeEnum.OAUTH_KEYS]: makeKeysBody,
  };
  const makeBody =
    authenticationTypeMap[tool?.authentication?.method] || makeKeysBody;
  const body = makeBody();
  return {
    body,
    method: data.tool_user_id ? "PUT" : "POST",
    url: data.tool_user_id
      ? `/api/v1/tool/${data.tool_user_id}/LinkToolUser`
      : `/api/v1/tool/${tool.id}/LinkToolUser`,
  };
};
