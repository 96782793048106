import {
  Box,
  Button,
  Flex,
  Grid,
  Stack,
  Text,
  theme,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import Loader from "../../utils/Loader/Loader";
import Layout from "../Layout/Layout";
import ActualFormAfterConfiguration from "./ActualFormAfterConfiguration";
import AutomatedWhatsappMessageForField from "./AuthomatedFollowUp";
import ConfigurationForm from "./ConfigurationForm";
import CreateFormGuide from "./CreateFormGuide";
import DetailsForm from "./DetaillsForm";
import useConfigureForm from "./useConfigureForm";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { getHostDomain } from "../../utils/utils";

export default function ConfigureForms() {
  const baseUrl = getHostDomain();
  const {
    fieldsForm,
    attributesForm,
    fields,
    formDetailsForm,
    onSetFields,
    status: formLoadStatus,
  } = useConfigureForm();
  const { reset: resetFormDetails } = formDetailsForm;
  const formLoading = formLoadStatus === "loading";
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
    getValues,
  } = fieldsForm;
  const [selectedField, setSelectedField] = useState(null);
  const resetFieldsForm = () =>
    reset({
      type: "text",
      description: "",
      label: "",
      name: "",
      privacy_content_url: "",
      options: [],
    });

  const onAddField = (newField) => {
    if (selectedField) {
      onSetFields((prevFields) =>
        prevFields.map((prevField) =>
          prevField.name === selectedField.name ? newField : prevField
        )
      );
      resetFieldsForm();
      return;
    }
    if (fields.find((field) => field.name === newField.name)) {
      toast.error("Two fields with same name cannot be created");
      return;
    }
    resetFieldsForm();
    onSetFields([...fields, newField]);
  };
  const onRemoveField = () => {
    if (!selectedField) return;
    onSetFields((prevFields) =>
      prevFields.filter((prevField) => prevField.name !== selectedField?.name)
    );
    setSelectedField(null);
    resetFieldsForm();
  };
  const onSetFormFields = (updatedFormFields) => {
    onSetFields(updatedFormFields);
  };
  const onSubmit = handleSubmit((data) => {
    onAddField(data);
    if (selectedField) setSelectedField(null);
  });
  const onSelectFieldFromListOfFields = (field) => {
    setSelectedField(field);
    reset(field);
  };

  const { formId } = useParams();
  const [status, setStatus] = useState("idle");
  const onUpdateForm = async () => {
    if (!fields.length) {
      toast.error("Form cannot be saved without fields !");
      return;
    }
    try {
      setStatus("loading");
      await fetchController(baseUrl + `/api/v1/form/${formId}`, "PUT", {
        form_type: formDetailsForm.getValues("type"),
        webhook_id: formDetailsForm.getValues("webhook_id") || null,
        name: formDetailsForm.getValues("name"),
        fields: fields.map((field) =>
          field.type === "select"
            ? {
                ...field,
                options: field.options.map((field) => field.option),
              }
            : field
        ),
        attributes: {
          text: attributesForm.getValues("followUpText"),
          automated_follow_up: attributesForm.getValues("automate"),
          contact_field: attributesForm.getValues("contactField"),
          channel: attributesForm.getValues("automate_type"),

          twilio_auth:
            attributesForm.getValues("automate_type") === "sms"
              ? attributesForm.getValues("twilio_auth")
              : undefined,
          phone_number:
            attributesForm.getValues("automate_type") === "sms"
              ? attributesForm.getValues("phone_number")
              : undefined,
          sid:
            attributesForm.getValues("automate_type") === "sms"
              ? attributesForm.getValues("sid")
              : undefined,
          send_me_too: attributesForm.getValues("should_send_me_too")
            ? attributesForm.getValues("send_me_too")
            : undefined,
          mandatory_submission: attributesForm.getValues(
            "mandatory_submission"
          ),
        },
        trigger_instructions: formDetailsForm.getValues("triggerInstructions"),
        org_id: null,
        submit_label: formDetailsForm.getValues("submitLabel"),
        contact_mapping: {
          ...getValues("contact_mapping")
        }
      });
      setStatus("success");
      toast.info("Form updated !");
    } catch (error) {
      toast.error("Some error occured");
    } finally {
      setStatus("idle");
    }
  };
  const onCreateNewForm = async () => {
    if (!fields.length) {
      toast.error("Form cannot be saved without fields !");
      return;
    }
    try {
      setStatus("loading");
      await fetchController(baseUrl + `/api/v1/form`, "POST", {
        form_type: formDetailsForm.getValues("type"),
        name: formDetailsForm.getValues("name"),
        webhook_id: formDetailsForm.getValues("webhook_id") || null,
        fields: fields.map((field) =>
          field.type === "select"
            ? {
                ...field,
                options: field.options.map((field) => field.option),
              }
            : field
        ),
        trigger_instructions: formDetailsForm.getValues("triggerInstructions"),
        attributes: {
          text: attributesForm.getValues("followUpText"),
          automated_follow_up: attributesForm.getValues("automate"),
          contact_field: attributesForm.getValues("contactField"),
          channel: attributesForm.getValues("automate_type"),
          twilio_auth:
            attributesForm.getValues("automate_type") === "sms"
              ? attributesForm.getValues("twilio_auth")
              : undefined,
          phone_number:
            attributesForm.getValues("automate_type") === "sms"
              ? attributesForm.getValues("phone_number")
              : undefined,
          sid:
            attributesForm.getValues("automate_type") === "sms"
              ? attributesForm.getValues("sid")
              : undefined,
          send_me_too: attributesForm.getValues("should_send_me_too")
            ? attributesForm.getValues("send_me_too")
            : undefined,
          mandatory_submission: attributesForm.getValues(
            "mandatory_submission"
          ),
        },
        org_id: null,
        submit_label: formDetailsForm.getValues("submitLabel"),
      });
      resetFieldsForm();
      resetFormDetails();
      onSetFields([]);
      setStatus("success");
      toast.success("Form created !");
    } catch (error) {
      toast.error("Some error occured");
    } finally {
      setStatus("idle");
    }
  };
  const [createFormTourVisibility, setCreateFormTourVisibility] = useState(
    localStorage.getItem("createFormTourVisibility") ? false : true
  );
  const onCloseTour = () => {
    localStorage.setItem("createFormTourVisibility", false);
    setCreateFormTourVisibility(false);
  };
  const isFormSubmitting = status === "loading";
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, cardBg: bg } = agency;
  return (
    <Layout>
      <Box
        border="1px"
        borderRadius="md"
        h="90svh"
        padding={4}
        overflowY={"auto"}
      >
        <Text fontSize="xl" marginBlock={2} fontWeight="bold" color={textColor}>
          {formId ? "Update the form" : "Add a new form"}
        </Text>
        {createFormTourVisibility ? (
          <CreateFormGuide onCloseTour={onCloseTour} />
        ) : null}
        <Box marginBlock={2}>
          <DetailsForm
            formDetailsForm={formDetailsForm}
            fields={fields}
            attributesForm={attributesForm}
          />
        </Box>
        <Stack spacing={3}>
          <Grid gap={3} gridTemplateColumns={"1fr 1fr"}>
            <ConfigurationForm
              errors={errors}
              watch={watch}
              setValue={setValue}
              onRemoveField={onRemoveField}
              selectedField={selectedField}
              register={register}
              handleSubmit={onSubmit}
              control={control}
            />
            {fields.length ? (
              <Box>
                {formLoading ? (
                  <Loader />
                ) : (
                  <ActualFormAfterConfiguration
                    fields={fields}
                    submitLabel={formDetailsForm.watch("submitLabel")}
                    onSetFormFields={onSetFormFields}
                    selectedField={selectedField}
                    onSelectFieldFromListOfFields={
                      onSelectFieldFromListOfFields
                    }
                  />
                )}
              </Box>
            ) : null}
          </Grid>
          <form
            onSubmit={attributesForm.handleSubmit(
              formId ? onUpdateForm : onCreateNewForm
            )}
          >
            <Box bg={bg} padding={3} borderRadius={"md"}>
              <AutomatedWhatsappMessageForField
                fields={fields}
                automateForm={attributesForm}
              />
            </Box>
            <Flex
              marginBlock={2}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Button
                isDisabled={!fields.length}
                isLoading={formLoading || isFormSubmitting}
                type="submit"
                colorScheme={buttonColorScheme}
                margin={"auto"}
              >
                {formId ? "Update form" : "Create Form"}
              </Button>
            </Flex>
          </form>
        </Stack>
      </Box>
    </Layout>
  );
}
