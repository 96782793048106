import { EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  IconButton,
  Image,
  theme,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { LiaToolsSolid } from "react-icons/lia";
import CardRibbon from "./CardRibbon";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { useContext } from "react";

export default function ToolItem({ tool, onOpenTool, editToolOpen, setSelectedToolId }) {
  const handleSetupClick = () => {
    onOpenTool(tool);
  };

  const handleToolEdit = (tool) => {
    setSelectedToolId(tool.id)
    editToolOpen()
  }
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, cardBg } = agency;
  return (
    <>
      <Card bg={cardBg} width={"200px"}>
        <CardBody position={"relative"} overflow={"hidden"}>
          {tool.tool_type === "user" && (
            <>
              <CardRibbon text={"Custom Tool"} />
              <IconButton
                isRound={true}
                size="xs"
                icon={<EditIcon />}
                position={"absolute"}
                top={"5px"}
                right={"5px"}
                title="Edit Tool"
                onClick={() => handleToolEdit(tool)}
              />
            </>
          )}
          <Flex
            justifyContent={"space-between"}
            flexDir={"column"}
            alignItems={"center"}
            h={"100%"}
          >
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              flexDir={"column"}
              h={"100%"}
            >
              {tool.logo_url ? (
                <Image
                  objectFit={"contain"}
                  margin={"auto"}
                  width={100}
                  h={100}
                  src={tool.logo_url}
                  alt="Bamboo"
                  borderRadius="lg"
                />
              ) : (
                <LiaToolsSolid
                  size={70}
                  style={{
                    margin: "auto",
                  }}
                />
              )}
              <Box marginBlock={3}>
                <Heading textAlign={"center"} size="sm">
                  {tool.name}
                </Heading>
              </Box>
            </Flex>

            <Tooltip
              label="Enable Tool to begin the Setup"
              placement="bottom"
              isDisabled={tool.enabled}
            >
              <Button
                isDisabled={!tool.enabled}
                onClick={handleSetupClick}
                margin={"auto"}
                size={"xs"}
                colorScheme={buttonColorScheme}
              >
                Setup
              </Button>
            </Tooltip>
          </Flex>
        </CardBody>
      </Card>
    </>
  );
}
