import { Box, Flex, Grid, useDisclosure } from "@chakra-ui/react";
import ToolItem from "./ToolItem";
import ToolUserModal from "./ToolUserModal";
import useToolModal from "./useToolModal";
import ToolFunctionModal from "./ToolFunctionModals/ToolFunctionModal";
import AddEditToolModal from "./AddEditToolModal";
import { useState } from "react";

export default function ToolsList({ filteredTools, getToolList }) {
  const { toolId, closeToolModal, openToolModal, isOpen } = useToolModal();
  const [selectedToolId, setSelectedToolId] = useState("")

  const {
    isOpen: isToolFunctionsOpen,
    onOpen: openToolFunctions,
    onClose: closeToolFunctions,
  } = useDisclosure();

  const {
    isOpen: isEditToolOpen,
    onOpen: editToolOpen,
    onClose: editToolClose,
  } = useDisclosure();

  const selectedTool = filteredTools.find((tool) => tool.id === toolId);

  return (
    <Box>
      <Flex
        maxH={"70svh"}
        overflowY={"auto"}
        flexWrap={"wrap"}
        gap={4}
      >
        {filteredTools.map((tool, index) => (
          <ToolItem
            tool={tool}
            key={index}
            onOpenTool={openToolModal}
            editToolOpen={editToolOpen}
            setSelectedToolId={setSelectedToolId}
          />
        ))}
      </Flex>

      {selectedTool && isOpen && !isEditToolOpen && !isToolFunctionsOpen && (
        <ToolUserModal
          isOpen={isOpen}
          onClose={closeToolModal}
          tool={selectedTool}
        />
      )}

      {selectedToolId && isEditToolOpen && (
        <AddEditToolModal
          isOpen={isEditToolOpen}
          onClose={editToolClose}
          toolId={selectedToolId}
          refreshToolList={getToolList}
          openToolFunctions={openToolFunctions}
        />
      )}

      {selectedToolId && isToolFunctionsOpen && (
        <ToolFunctionModal
          isOpen={isToolFunctionsOpen}
          onClose={closeToolFunctions}
          toolId={selectedToolId}
          closeToolModal={closeToolModal}
        />
      )}
    </Box>
  );
}
