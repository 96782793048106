import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import { Box, Button, Heading, Text, useDisclosure, Spinner } from "@chakra-ui/react";
import AddPromptModal from "../Modals/AddPrompt";
import Table from "../Tables/Table";
import { fetchController } from "../../utils/FetchController/fetchController";
import Loader from "../../utils/Loader/Loader";
import { getHostDomain } from "../../utils/utils";

const Prompt = () => {
  const baseUrl = getHostDomain();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [heads, setHeads] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true); // State to manage loading

  const endpoint = "/api/v1/prompt/list?page=1&size=50";
  const urlHit = baseUrl + endpoint;

  const hitFetch = async () => {
    try {
      setLoading(true); // Set loading to true before making the API call
      const getData = await fetchController(urlHit, "GET");
      console.log(getData, ">>>>>>>>>>>>");
      if (getData?.data) {
        const headsData = ["name", "description"];
        const columns = headsData.map((key) => ({
          Header: key,
          accessor: key,
          Filter: ({ column }) => <input {...column.filterProps} />,
        }));
        setTableData(getData.data.items);
        setHeads(columns);
      }
    } catch (error) {
    } finally {
      setLoading(false); // Set loading to false after the API call is completed
    }
  };

  useEffect(() => {
    hitFetch();
  }, []);

  return (
    <Layout>
      <Box border="1px" h="90vh" p="3" overflowY={"auto"} borderRadius="md">
        <Box display="flex" justifyContent="space-between">
          <Text fontSize="xl" fontWeight="bold" color="#3182ce" p={2}>
            Prompt
          </Text>
          <Button colorScheme="blue" onClick={() => onOpen()}>
            Add a Prompt
          </Button>
        </Box>
        <Box height="78vh" overflowY="scroll">
          {loading ? ( // Render Spinner while loading
            <Loader />
          ) : (
            <Table fetch={hitFetch} columns={heads} data={tableData} table="Prompt" />
          )}
        </Box>
      </Box>
      <AddPromptModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </Layout>
  );
};

export default Prompt;
