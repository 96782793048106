export default function IframeWidget({ widgetId, widgetType }) {
  const src = `https://cdn.insighto.ai/${
    widgetType === "chat" ? "bot" : "call"
  }-iframe.html?widgetId=${widgetId}&theme=`;
  return (
    <iframe
      src={src}
      allow="microphone"
      style={{
        border: "none",
        width: "100%",
        maxWidth: "800px",
        height: "600px",
      }}
    ></iframe>
  );
}
