import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Checkbox,
  Flex,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
// import Select from "react-select";
import { AddKeyModal } from "../../Modals/AddKeyModal";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { toast } from "react-toastify";
import { SettingContext } from "../Reducer/SettingProvider";
import { AgencyContext } from "../AgencyProvider/AgencyProvider";
import CustomSelect from "../../../utils/CustomSelect/CustomSelect";
import { getHostDomain } from "../../../utils/utils";
import KeyStatusCheck from "./KeyStatusCheck";

const YourKeys = () => {
  const baseUrl = getHostDomain();
  const keyEndPoint = `/api/v1/provider/list?page=1&size=50`;
  const userProviderEndPoint = "/api/v1/user_provider_key/list?page=1&size=50";
  const { state } = useContext(SettingContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedLabel, setSelectedLabel] = useState("");
  const [selectedOpenaiOption, setSelectedOpenaiOption] = useState(null);
  const [selectedElevenlabsOption, setSelectedElevenlabsOption] =
    useState(null);
  const [useKeys, setUseKeys] = useState(false);
  const [selectedAzureSpeechOption, setSelectedAzureSpeechOption] =
    useState(null);
  const [id, setId] = useState("");
  const [keys, setKeys] = useState({
    openaiKeys: [],
    elevenlabsKeys: [],
    azureSpeechKeys: [],
  });

  const [checkStatus, setCheckStatus] = useState(false);
  const [statusResponse, setStatusResponse] = useState(null);

  useEffect(() => {
    fetchKeys();
  }, []);

  const fetchKeys = async () => {
    const userProviderKeyList = await fetchController(
      baseUrl + userProviderEndPoint,
      "GET"
    );
    const response = await fetchController(baseUrl + keyEndPoint, "GET");
    setId(userProviderKeyList.data.items[0]?.id);
    setUseKeys(userProviderKeyList.data.items[0]?.status);
    const openaiKeys = response.data.items
      .filter((item) => item.provider_name === "openai")
      .map((item) => ({ label: item.name, value: item.id }));

    const elevenlabsKeys = response.data.items
      .filter((item) => item.provider_name === "elevenlabs")
      .map((item) => ({ label: item.name, value: item.id }));

    const azureSpeechKeys = response.data.items
      .filter((item) => item.provider_name === "azure_speech")
      .map((item) => ({ label: item.name, value: item.id }));

    setKeys({
      openaiKeys,
      elevenlabsKeys,
      azureSpeechKeys,
    });

    const userKey = userProviderKeyList?.data.items[0];

    if (userKey) {
      const matchedOpenaiKey = openaiKeys.find(
        (key) => key.value === userKey.openai_key
      );
      if (matchedOpenaiKey) {
        setSelectedOpenaiOption(matchedOpenaiKey);
      } else {
        setSelectedOpenaiOption(null);
      }

      const matchedElevenlabsKey = elevenlabsKeys.find(
        (key) => key.value === userKey.elevenlabs_key
      );
      if (matchedElevenlabsKey) {
        setSelectedElevenlabsOption(matchedElevenlabsKey);
      } else {
        setSelectedElevenlabsOption(null);
      }

      const matchedAzureSpeechKey = azureSpeechKeys.find(
        (key) => key.value === userKey.azure_speech_key
      );
      if (matchedAzureSpeechKey) {
        setSelectedAzureSpeechOption(matchedAzureSpeechKey);
      } else {
        setSelectedAzureSpeechOption(null);
      }
    }
  };

  const storedOrg = localStorage.getItem("org");
  const handleAddKey = async (name, key, region) => {
    try {
      const selectedOrg = JSON.parse(storedOrg);
      const body = {
        org_id: null,
        name,
        provider_key: key,
        provider_name: selectedLabel.value,
        attributes: region ? { region } : {},
        status: true,
      };
      await fetchController(baseUrl + "/api/v1/provider", "POST", body);
      fetchKeys();
      toast.success("Key Added");
    } catch (error) {
      toast.error("Error adding key. Please try again later.");
    }
  };

  const handleProviderChange = (provider) => {
    setSelectedLabel({ label: provider.label, value: provider.value });
    onOpen();
  };

  const handleSubmit = async () => {
    try {
      const selectedOrg = JSON.parse(storedOrg);
      const body = {
        org_id: null,
        openai_key: selectedOpenaiOption.value,
        elevenlabs_key: selectedElevenlabsOption.value,
        azure_speech_key: selectedAzureSpeechOption.value,
        attributes: {},
        status: useKeys,
      };
      const method = id === undefined ? "POST" : "PUT";
      const endPoint =
        id === undefined
          ? "/api/v1/user_provider_key"
          : `/api/v1/user_provider_key/${id}`;
      const data = await fetchController(baseUrl + endPoint, method, body);
      data.data && fetchKeys();
      toast.success("User provider key updated");
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, colorPreset } = agency;

  const handleStatusCheck = async () => {
    try {
      setCheckStatus(true);
      const response = await fetchController(
        baseUrl + `/api/v1/user_provider_key/${id}/check_status`,
        "GET"
      );
      setStatusResponse(response?.data?.checks);
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong");
    } finally {
      setCheckStatus(false);
    }
  };

  useEffect(() => {
    console.log("keys", keys);
  }, [keys]);

  return (
    <Box border="1px" p="5" borderRadius="md" h="81vh" overflowY="auto">
      <Flex justifyContent={"space-between"} align="center" mb="4">
        <Text fontWeight="bold" fontSize="22px" color={textColor}>
          Your Provider Keys
        </Text>
        <Tooltip
          label={"Select & save your keys to test"}
          aria-label="A tooltip"
          isDisabled={id}
        >
          <Button
            colorScheme={buttonColorScheme}
            onClick={handleStatusCheck}
            isLoading={checkStatus}
            isDisabled={!state?.credits?.byok > 0 || !id}
          >
            Check Status
          </Button>
        </Tooltip>
      </Flex>

      <Card variant={"elevated"}>
        <CardBody>
          <Checkbox
            isChecked={useKeys}
            onChange={(e) => {
              setUseKeys(e.target.checked);
            }}
            isDisabled={!state?.credits?.byok > 0}
            colorScheme={buttonColorScheme}
          >
            <Tooltip
              label={"Please get the BYOK add-on to enable this"}
              aria-label="A tooltip"
              placement="bottom-start"
            >
              Use my keys
            </Tooltip>
          </Checkbox>
          <Flex display="flex" alignItems="center" gap="15px" mr="2" mt="10px">
            <Text width="140px" fontWeight="bold">
              OpenAI Key
            </Text>
            <Box width="400px">
              <CustomSelect
                options={keys.openaiKeys}
                value={selectedOpenaiOption?.value}
                onChange={(selectedOption) => {
                  setSelectedOpenaiOption(selectedOption);
                }}
                isDisabled={!state?.credits?.byok > 0}
              />
            </Box>
            <Button
              colorScheme={buttonColorScheme}
              onClick={() =>
                handleProviderChange({ label: "OpenAI Keys", value: "openai" })
              }
              isDisabled={!state?.credits?.byok > 0}
            >
              Add
            </Button>
          </Flex>

          <Flex display="flex" alignItems="center" gap="15px" mr="2" mt="7px">
            <Text width="140px" fontWeight="bold">
              ElevenLabs Key
            </Text>
            <Box width="400px">
              <CustomSelect
                options={keys.elevenlabsKeys}
                value={selectedElevenlabsOption?.value}
                onChange={(selectedOption) => {
                  setSelectedElevenlabsOption(selectedOption);
                }}
                isDisabled={!state?.credits?.byok > 0}
              />
            </Box>
            <Button
              colorScheme={buttonColorScheme}
              onClick={() =>
                handleProviderChange({
                  label: "ElevenLabs Keys",
                  value: "elevenlabs",
                })
              }
              isDisabled={!state?.credits?.byok > 0}
            >
              Add
            </Button>
          </Flex>

          <Flex display="flex" alignItems="center" gap="15px" mr="2" mt="7px">
            <Text width="140px" fontWeight="bold">
              Azure Speech Key
            </Text>
            <Box width="400px">
              <CustomSelect
                options={keys.azureSpeechKeys}
                value={selectedAzureSpeechOption?.value}
                onChange={(selectedOption) => {
                  setSelectedAzureSpeechOption(selectedOption);
                }}
                isDisabled={!state?.credits?.byok > 0}
              />
            </Box>

            <Button
              colorScheme={buttonColorScheme}
              onClick={() =>
                handleProviderChange({
                  label: "Azure Speech Keys",
                  value: "azure_speech",
                })
              }
              isDisabled={!state?.credits?.byok > 0}
            >
              Add
            </Button>
          </Flex>
          <Text mt={5} fontWeight="bold">
            Add & select key for each of the provider above to unlock limits
          </Text>
        </CardBody>
        <CardFooter justifyContent={"flex-end"}>
          <Button
            onClick={handleSubmit}
            colorScheme={buttonColorScheme}
            mr={3}
            my={2}
            isDisabled={
              !selectedOpenaiOption ||
              !selectedElevenlabsOption ||
              !selectedAzureSpeechOption
            }
          >
            Submit
          </Button>
        </CardFooter>
      </Card>

      {statusResponse && (
        <>
          <Flex justifyContent={"space-between"} align="center" my="4">
            <Text fontWeight="bold" fontSize="18px" color={textColor}>
              API keys status
            </Text>
          </Flex>
          <KeyStatusCheck checks={statusResponse} agencyTextColor={textColor} />
        </>
      )}

      <AddKeyModal
        isOpen={isOpen}
        onClose={onClose}
        onAdd={handleAddKey}
        labelName={selectedLabel}
      />
    </Box>
  );
};

export default YourKeys;
