import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Tag,
  TagCloseButton,
  TagLabel,
  Tooltip,
  Text,
  Box,
  Divider,
} from "@chakra-ui/react";
import { useRef, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { MdDeleteOutline } from "react-icons/md";
import { fileUploadFetch } from "../../utils/FetchController/fileUplaodFetch";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import { SettingContext } from "../Settings/Reducer/SettingProvider";
import ActionButtonsForm from "./AddActions";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { getHostDomain } from "../../utils/utils";
export default function CustomizationForm({
  setWidgetCustomizationOptions,
  onChangeWidgetCustomizationOption,
  widgetCustomizationOptions,
  userOpeningMessages,
  setUserOpeningMessages,
  handleOnLoad,
  setButtons,
  buttons,
}) {
  const baseUrl = getHostDomain();
  const customizeHelpRef = useRef("");
  const { state: activatedPlan, dispatch } = useContext(SettingContext);
  const handleClickCustomizeHelp = (e) => {
    e.preventDefault();
    if (customizeHelpRef.current.value) {
      // setCustomizeHelp([...customizeHelp, customizeHelpRef.current.value]);
      setUserOpeningMessages([
        ...userOpeningMessages,
        customizeHelpRef.current.value,
      ]);

      customizeHelpRef.current.value = "";
    }
  };
  const deleteIceBreakers = (e, val) => {
    const index = userOpeningMessages.indexOf(val);
    if (index > -1) {
      let Temp = [...userOpeningMessages];
      Temp.splice(index, 1);
      setUserOpeningMessages(Temp);
    }
  };
  const { widgetId } = useParams();
  const onChangeConversationsBotIcon = async (e) => {
    try {
      const files = e.currentTarget.files;
      if (!files.length) return;
      const formData = new FormData();
      formData.append("upload_bot_icon", files[0]);
      const url = baseUrl + `/api/v1/widget/${widgetId}/ConversationBotIcon`;
      await fileUploadFetch(formData, url);
      handleOnLoad();
    } catch (error) {
      toast.error("Some error occured");
    }
  };
  const removeBotIcon = async () => {
    try {
      const url = baseUrl + `/api/v1/widget/${widgetId}/BubbleBotIcon`;
      await fetchController(url, "DELETE");
      handleOnLoad();
    } catch (error) {
      toast.error("Some error occured");
    }
  };
  const removeConversationsBotIcon = async () => {
    try {
      const url = baseUrl + `/api/v1/widget/${widgetId}/ConversationBotIcon`;
      await fetchController(url, "DELETE");
      handleOnLoad();
    } catch (error) {
      toast.error("Some error occured");
    }
  };
  const onChangeBotIcon = async (e) => {
    try {
      const files = e.currentTarget.files;
      if (!files.length) return;
      const formData = new FormData();
      formData.append("upload_bubble_bot_icon", files[0]);
      const url = baseUrl + `/api/v1/widget/${widgetId}/BubbleBotIcon`;
      await fileUploadFetch(formData, url);
      handleOnLoad();
    } catch (error) {
      toast.error("Some error occured");
    }
  };
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, cardBg: bg } = agency;
  return (
    <Grid rounded="md" bg={bg} p={5} gap={2}>
      <Flex justifyContent={"center"} alignItems={"center"} gap={4}>
        <FormControl display={"grid"} gap={2}>
          <Flex justifyContent={"flex-start"} gap={4} alignItems={"center"}>
            <Text fontSize={16}>Conversation Avatar</Text>{" "}
            <MdDeleteOutline
              size={30}
              color="red"
              title="Remove icon"
              cursor={"pointer"}
              onClick={removeConversationsBotIcon}
            />
          </Flex>
          <Input
            onChange={onChangeConversationsBotIcon}
            width={"100%"}
            name="conversations_bot_icon"
            type="file"
            placeholder="Display Name"
          />
        </FormControl>
        <FormControl display={"grid"} gap={2}>
          <Flex justifyContent={"flex-start"} gap={4} alignItems={"center"}>
            <Text fontSize={16}> Bot Avatar</Text>
            <MdDeleteOutline
              size={30}
              color="red"
              title="Remove icon"
              cursor={"pointer"}
              onClick={removeBotIcon}
            />
          </Flex>
          <Input
            onChange={onChangeBotIcon}
            width={"100%"}
            name="bot_icon"
            type="file"
            placeholder="Bot Icon"
          />
        </FormControl>
      </Flex>
      <Divider />
      <FormControl>
        <FormLabel>Display Name</FormLabel>
        <Input
          width={"100%"}
          onChange={onChangeWidgetCustomizationOption}
          value={widgetCustomizationOptions.display_name}
          name="display_name"
          type="text"
          placeholder="Display Name"
        />
      </FormControl>
      <FormControl>
        <FormLabel>Bubble Text</FormLabel>
        <Input
          width={"100%"}
          onChange={onChangeWidgetCustomizationOption}
          value={widgetCustomizationOptions.bubble_text}
          name="bubble_text"
          type="text"
          placeholder="Display Name"
        />
      </FormControl>

      <FormControl>
        <FormLabel>Intro Message</FormLabel>
        <Input
          onChange={onChangeWidgetCustomizationOption}
          name="intro_message"
          value={widgetCustomizationOptions.intro_message}
          type="text"
          placeholder="Intro Message"
        />
      </FormControl>

      <FormControl>
        <FormLabel>Place holder text</FormLabel>
        <Input
          onChange={(e) => {
            const params = {
              style_params: {
                ...widgetCustomizationOptions.style_params,
                placeholder: e.currentTarget.value,
              },
            };
            setWidgetCustomizationOptions({
              ...widgetCustomizationOptions,
              ...params,
            });
          }}
          name="placeholder"
          value={widgetCustomizationOptions?.style_params?.placeholder}
          type="text"
          placeholder="Placeholder"
        />
      </FormControl>
      <Grid>
        <Text fontSize={"md"}>Customize Colors</Text>
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <FormControl
            style={{
              display: "flex",
              gap: "10px",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Input
              name="user_message_color"
              onChange={onChangeWidgetCustomizationOption}
              value={widgetCustomizationOptions.user_message_color}
              type="color"
              placeholder="User Message Color"
              borderRadius={15}
              p={1}
              className="widget__inputColor"
              border={0}
              height={"42px"}
              width={"42px"}
            />

            <Text mt={3} colorScheme="teal" fontWeight={500} fontSize={"1rem"}>
              User message
            </Text>
          </FormControl>
          <FormControl
            style={{
              display: "flex",
              gap: "10px",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Input
              name="ice_break_color"
              onChange={onChangeWidgetCustomizationOption}
              value={widgetCustomizationOptions.ice_break_color}
              type="color"
              borderRadius={15}
              p={1}
              className="widget__inputColor"
              border={0}
              height={"42px"}
              width={"42px"}
            />

            <Text mt={3} colorScheme="teal" fontWeight={500} fontSize={"1rem"}>
              Ice break color
            </Text>
          </FormControl>
        </Flex>
        <Flex>
          <FormControl
            style={{
              display: "flex",
              gap: "10px",
              width: "100%",
            }}
          >
            <Input
              name="header_color"
              onChange={onChangeWidgetCustomizationOption}
              value={widgetCustomizationOptions.header_color}
              type="color"
              border={0}
              className="widget__inputColor"
              placeholder="Header"
              p={1}
              height={"42px"}
              width={"42px"}
            />
            <Text mt={3} colorScheme="teal" fontWeight={500} fontSize={"1rem"}>
              Header
            </Text>
          </FormControl>
          <FormControl
            style={{
              display: "flex",
              gap: "10px",
              flexDirection: "row",
            }}
          >
            <Input
              type="color"
              placeholder="Bot Message Color"
              onChange={onChangeWidgetCustomizationOption}
              name="bot_message_color"
              value={widgetCustomizationOptions.bot_message_color}
              p={1}
              className="widget__inputColor"
              borderRadius={15}
              border={0}
              height={"42px"}
              width={"42px"}
            />
            <Text mt={3} colorScheme="teal" fontWeight={500} fontSize={"1rem"}>
              Bot message
            </Text>
          </FormControl>
        </Flex>
        <Flex justifyContent={"center"} alignItems={"center"}>
          <FormControl
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            <Input
              name="bot_icon_color"
              onChange={onChangeWidgetCustomizationOption}
              value={widgetCustomizationOptions.bot_icon_color}
              type="color"
              border={0}
              className="widget__inputColor"
              placeholder="Header"
              p={1}
              height={"42px"}
              width={"42px"}
            />
            <Text mt={3} colorScheme="teal" fontWeight={500} fontSize={"1rem"}>
              Bot Icon color
            </Text>
          </FormControl>
          <FormControl
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            <Input
              name="user_text_message_color"
              onChange={onChangeWidgetCustomizationOption}
              value={widgetCustomizationOptions.user_text_message_color}
              type="color"
              border={0}
              className="widget__inputColor"
              p={1}
              height={"42px"}
              width={"42px"}
            />
            <Text mt={3} colorScheme="teal" fontWeight={500} fontSize={"1rem"}>
              User text message color
            </Text>
          </FormControl>
        </Flex>
        <Flex>
          <FormControl
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            <Input
              name="bot_text_message_color"
              onChange={onChangeWidgetCustomizationOption}
              value={widgetCustomizationOptions.bot_text_message_color}
              type="color"
              border={0}
              className="widget__inputColor"
              p={1}
              height={"42px"}
              width={"42px"}
            />
            <Text mt={3} colorScheme="teal" fontWeight={500} fontSize={"1rem"}>
              Bot text message color
            </Text>
          </FormControl>
          <FormControl
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            <Input
              name="header_text_color"
              onChange={onChangeWidgetCustomizationOption}
              value={widgetCustomizationOptions.header_text_color}
              type="color"
              border={0}
              className="widget__inputColor"
              p={1}
              height={"42px"}
              width={"42px"}
            />
            <Text mt={3} colorScheme="teal" fontWeight={500} fontSize={"1rem"}>
              Header text color
            </Text>
          </FormControl>
        </Flex>
        <FormControl
          style={{
            display: "flex",
            gap: "10px",
          }}
        >
          <Input
            name="action_buttons_color"
            onChange={onChangeWidgetCustomizationOption}
            value={widgetCustomizationOptions.action_buttons_color}
            type="color"
            border={0}
            className="widget__inputColor"
            p={1}
            height={"42px"}
            width={"42px"}
          />
          <Text mt={3} colorScheme="teal" fontWeight={500} fontSize={"1rem"}>
            Action Buttons Color
          </Text>
        </FormControl>
      </Grid>
      <Checkbox
        paddingLeft={2}
        onChange={onChangeWidgetCustomizationOption}
        isChecked={widgetCustomizationOptions.remove_branding}
        name="remove_branding"
        spacing={"0.5rem"}
        size={"lg"}
        isDisabled={!activatedPlan.remove_branding}
        width={"300px"}
        colorScheme={buttonColorScheme}
      >
        <Tooltip
          label={
            !activatedPlan.remove_branding
              ? "Please upgrade to Gold Plan or above"
              : ""
          }
          aria-label="A tooltip"
          closeOnClick
        >
          <Text colorScheme="teal" fontWeight={500} fontSize={"1rem"}>
            Remove Branding
          </Text>
        </Tooltip>
      </Checkbox>
      <Checkbox
        paddingLeft={2}
        onChange={(e) => {
          const params = {
            style_params: {
              auto_close_bubble_everytime: e.target.checked,
              auto_open_widget:
                widgetCustomizationOptions.style_params?.auto_open_widget,
            },
          };
          setWidgetCustomizationOptions({
            ...widgetCustomizationOptions,
            ...params,
          });
        }}
        isChecked={
          widgetCustomizationOptions.style_params?.auto_close_bubble_everytime
        }
        name="auto_open_widget"
        spacing={"0.5rem"}
        size={"lg"}
        width={"300px"}
        colorScheme={buttonColorScheme}
      >
        <Tooltip
          aria-label="auto_open_widget"
          label="Automatically close the bubble."
        >
          <Text colorScheme="teal" fontWeight={500} fontSize={"1rem"}>
            Auto close bubble everytime
          </Text>
        </Tooltip>
      </Checkbox>
      <Checkbox
        paddingLeft={2}
        onChange={(e) => {
          const params = {
            style_params: {
              auto_open_widget: e.target.checked,
              auto_close_bubble_everytime:
                widgetCustomizationOptions.style_params
                  ?.auto_close_bubble_everytime,
            },
          };
          setWidgetCustomizationOptions({
            ...widgetCustomizationOptions,
            ...params,
          });
        }}
        isChecked={widgetCustomizationOptions.style_params?.auto_open_widget}
        name="remove_branding"
        spacing={"0.5rem"}
        size={"lg"}
        width={"300px"}
        colorScheme={buttonColorScheme}
      >
        <Tooltip
          aria-label="auto_close_bubble_everytime"
          label="Opens the widget on page load."
        >
          <Text colorScheme="teal" fontWeight={500} fontSize={"1rem"}>
            Auto open widget
          </Text>
        </Tooltip>
      </Checkbox>
      <Checkbox
        paddingLeft={2}
        onChange={(e) => {
          const params = {
            style_params: {
              ...widgetCustomizationOptions.style_params,
              hide_ice_breaker_for_session: e.currentTarget.checked,
            },
          };
          setWidgetCustomizationOptions({
            ...widgetCustomizationOptions,
            ...params,
          });
        }}
        isChecked={
          widgetCustomizationOptions.style_params?.hide_ice_breaker_for_session
        }
        name="hide_ice_breaker_for_session"
        spacing={"0.5rem"}
        size={"lg"}
        width={"300px"}
        colorScheme={buttonColorScheme}
      >
        <Tooltip
          aria-label="auto_close_bubble_everytime"
          label="Remove ice breaker after first time use"
        >
          <Text colorScheme="teal" fontWeight={500} fontSize={"1rem"}>
            Hide ice breaker after first time use
          </Text>
        </Tooltip>
      </Checkbox>
      <Checkbox
        paddingLeft={2}
        onChange={(e) => {
          const params = {
            style_params: {
              ...widgetCustomizationOptions.style_params,
              show_reset_button: e.currentTarget.checked,
            },
          };
          setWidgetCustomizationOptions({
            ...widgetCustomizationOptions,
            ...params,
          });
        }}
        isChecked={widgetCustomizationOptions.style_params?.show_reset_button}
        name="show_reset_button"
        spacing={"0.5rem"}
        size={"lg"}
        width={"300px"}
      >
        <Tooltip
          aria-label="auto_close_bubble_everytime"
          label="Remove ice breaker after first time use"
        >
          <Text colorScheme="teal" fontWeight={500} fontSize={"1rem"}>
            Show reset button
          </Text>
        </Tooltip>
      </Checkbox>
      <Box borderWidth="1px" borderColor="#e2e8f0" borderRadius="4px" p={2}>
        <Text mt={1} colorScheme="teal" fontWeight={500} fontSize={"1rem"}>
          Action buttons
        </Text>
        <Box mt={3}>
          <ActionButtonsForm buttons={buttons} setButtons={setButtons} />
        </Box>
      </Box>

      <form
        className="form-inline"
        style={{
          display: "flex",
          gap: "10px",
          padding: "10px 0px",
        }}
        onSubmit={handleClickCustomizeHelp}
      >
        <FormControl isRequired>
          <FormLabel> Ice Breakers</FormLabel>

          <Input
            placeholder="Ice Breakers"
            ref={customizeHelpRef}
            name="newHelp"
          />
        </FormControl>
        <Button mt={8} colorScheme={buttonColorScheme} type="submit">
          Add
        </Button>
      </form>
      <Flex
        alignItems={"center"}
        borderTop={"1px solid lightgray"}
        padding={2}
        justifyContent={"start"}
        gap={2}
        flexWrap="wrap"
        border={"1px sloid red"}
        marginTop={"reset"}
      >
        {userOpeningMessages.map((msg, index) => (
          <Tag
            size={"lg"}
            key={index}
            borderRadius="full"
            variant="solid"
            colorScheme={buttonColorScheme}
            display={"flex"}
            flexWrap={"wrap"}
          >
            <TagLabel>{msg}</TagLabel>
            <TagCloseButton
              index={index}
              onClick={(e, index) => deleteIceBreakers(e, msg)}
            />
          </Tag>
        ))}
      </Flex>
    </Grid>
  );
}
