import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  Textarea, // Import the Textarea component
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Editor2 from "../../utils/Editor/Editor2";
import { fetchController } from "../../utils/FetchController/fetchController";
import { toast } from "react-toastify";
import { CiSquarePlus } from "react-icons/ci";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDropupCircle,
} from "react-icons/io";
import { getHostDomain } from "../../utils/utils";

const AddPromptModal = ({ isOpen, onOpen, onClose }) => {
  const baseUrl = getHostDomain();
  const [prompt, setPrompt] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isVarLoading, setIsVarLoading] = useState(false);

  const [name_key, setNameKey] = useState("");
  const [label, setLabelKey] = useState("");

  const [description, setDescriptionKey] = useState("");
  const finalRef = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const endpoint = "/api/v1/prompt";
  const variableEndpoint = "/api/v1/variable";
  const urlHit = baseUrl + endpoint;
  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const body = {
        ...data,
        prompt_template: prompt,
      };
      const getData = await fetchController(urlHit, "POST", body);

      // Assuming getData contains the response from the API
      if (getData) {
        toast.success("Prompt Added");
        // You can reset form values or close the modal here if needed
      } else {
        throw new Error("Failed to add prompt");
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };
  const [isInnerBoxClicked, setInnerBoxClicked] = useState(false);

  const handleInnerBoxClick = () => {
    setInnerBoxClicked(!isInnerBoxClicked);
  };

  const handleAddKey = async () => {
    try {
      setIsVarLoading(true);
      const body = {
        name: name_key,
        description,
        label_key: label,
      };
      const urlHit = baseUrl + variableEndpoint;

      const getData = await fetchController(urlHit, "POST", body);

      // Assuming getData contains the response from the API
      if (getData) {
        toast.success("Prompt Added");
        setInnerBoxClicked(false);
        setNameKey("");
        setDescriptionKey("");
        setLabelKey("");
        // You can reset form values or close the modal here if needed
      } else {
        throw new Error("Failed to add prompt");
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setIsVarLoading(false);
    }
  };
  return (
    <>
      <Modal
        size="xl"
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setValue("name", "");
          setValue("description", ""); // Clear description field on close
        }}
      >
        <ModalOverlay />
        <ModalContent
          height="80vh"
          width="95%"
          maxWidth="900px"
          overflow="scroll"
        >
          <ModalHeader>Add a Prompt</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* First line */}
              <Stack direction="row" mb="4">
                <Box flex="1">
                  <Input
                    placeholder="Name"
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      Name is required
                    </p>
                  )}
                </Box>
              </Stack>

              {/* Second line: Description field */}
              <Stack direction="row" mb="4">
                <Box flex="1">
                  <Textarea
                    placeholder="only for your reference, not used as part of the prompt"
                    {...register("description")}
                    resize="vertical" // Allow vertical resizing of textarea
                    minHeight="80px" // Set a minimum height
                  />
                </Box>
              </Stack>
              <Box cursor="pointer" p={3} bg="#F9FAFB">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  onClick={handleInnerBoxClick}
                >
                  <Text fontSize="14px" fontWeight="bold">
                    Variable
                  </Text>
                  {isInnerBoxClicked ? (
                    <IoIosArrowDropupCircle
                      size="20px"
                      color="#3182ce"
                      cursor="pointer"
                    />
                  ) : (
                    <IoIosArrowDropdownCircle
                      size="20px"
                      color="#3182ce"
                      cursor="pointer"
                    />
                  )}
                </Box>
                <Box mt={2}>
                  {isInnerBoxClicked && (
                    <>
                      <Input
                        height="30px"
                        width="30%"
                        type="text"
                        placeholder="Name"
                        value={name_key}
                        onChange={(e) => setNameKey(e.target.value)}
                      />

                      <Input
                        ml={2}
                        height="30px"
                        width="30%"
                        type="text"
                        placeholder="Enter key"
                        value={label}
                        onChange={(e) => setLabelKey(e.target.value)}
                      />
                      <Input
                        mt={2}
                        display="block"
                        height="30px"
                        width="61%"
                        type="text"
                        placeholder="Enter Description"
                        value={description}
                        onChange={(e) => setDescriptionKey(e.target.value)}
                      />

                      <Button
                        fontSize="13px"
                        height="28px"
                        mt={3}
                        colorScheme="blue"
                        onClick={handleAddKey}
                      >
                        {isVarLoading ? <Spinner size="sm" /> : "Add"}
                      </Button>
                    </>
                  )}
                </Box>
              </Box>

              {/* Editor2 component for the prompt */}
              <Box>
                <Editor2
                  isVarLoading={isVarLoading}
                  prompt={prompt}
                  setPrompt={setPrompt}
                />
              </Box>

              <Button width="80px" colorScheme="blue" type="submit" mt="50">
                {isLoading ? <Spinner size="sm" /> : "Submit"}
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddPromptModal;
