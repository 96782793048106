import { useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { useForm } from "react-hook-form";
import { getHostDomain } from "../../utils/utils";

export default function useWidgetForm(id) {
  const baseUrl = getHostDomain();
  const [status, setStatus] = useState("idle");
  const defaultValues = {
    header_name: "Insighto.AI",
    logo: "/bot.png",
    remove_branding: false,
    confirmation_message: "Call confirmed",
    button_label: "Call",
    theme: {
      button: {
        backgroundColor: "#3b81f6",
        color: "white",
      },
      app: {
        backgroundColor: "#FFFFFF",
      },
    },
  };
  const widgetForm = useForm({
    defaultValues,
  });
  const [widget, setWidget] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        setStatus("loading");
        const promises = [
          fetchController(
            baseUrl + `/api/v1/widget/${id}`
          ),
          fetchController(
            baseUrl + `/api/v1/widget/${id}/parameters`
          ),
        ];
        const [widgetResponse, widgetParamsResponse] = await Promise.all(
          promises
        );
        setWidget({
          ...widgetResponse.data,
          fields: widgetParamsResponse.data.form.fields || [],
        });
        const style_params = Object.keys(widgetResponse.data.style_params)
          .length
          ? widgetResponse.data.style_params
          : { theme: defaultValues.theme };
        widgetForm.reset({ ...defaultValues, ...style_params });
        setStatus("idle");
      } catch (error) {
        setWidget(null);
        setStatus("idle");
      }
    })();
  }, [id]);
  const loading = status === "loading";
  return { widget, status, loading, widgetForm };
}
