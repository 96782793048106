import { Box, Center, Flex, Heading, Icon, Tooltip } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { GoDotFill } from "react-icons/go";
import { fetchController } from "../../utils/FetchController/fetchController";
import Loader from "../../utils/Loader/Loader";
import { getHostDomain } from "../../utils/utils";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import Table from "../Tables/Table";
const SubAccountTable = () => {
  const [status, setStatus] = useState("idle");
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [heads, setHeads] = useState([]);
  const baseUrl = getHostDomain();
  const agency = useContext(AgencyContext);
  const { agencyData, textColor, buttonColorScheme } = agency;
  const agency_id = agencyData?.id;
  const loading = status === "loading" && isInitialLoad;
  const hitFetch = async () => {
    try {
      setStatus("loading");
      const urlHit = baseUrl + `/api/v1/agency/${agency_id}/list_users`;
      const getData = await fetchController(urlHit, "GET");
      if (getData?.data) {
        const updatedData = getData.data?.items.map((item) => {
          return {
            name: item.first_name + "" + item.last_name,
            email: item.email,
            phone: item.phone,
            id: item.id,
            active: item.is_active ? (
              <>
                <Tooltip label="Active" aria-label="Active">
                  <span>
                    <Icon as={GoDotFill} color="green.500" />
                  </span>
                </Tooltip>
              </>
            ) : (
              <>
                <Tooltip label="Not active" aria-label="Not active">
                  <span>
                    <Icon as={GoDotFill} color="red.500" />
                  </span>
                </Tooltip>
              </>
            ),
          };
        });

        const heads = ["active", "name", "email", "phone"];
        const columns = heads.map((key) => ({
          Header: key,
          accessor: key,
          Filter: ({ column }) => <input {...column.filterProps} />,
        }));
        setTableData(updatedData);
        setHeads(columns);
        setStatus("success");
        setIsInitialLoad(false);
      }
    } catch (error) {
      setStatus("failure");
    }
  };
  useEffect(() => {
    hitFetch();
  }, []);

  return (
    <Box h="75vh" overflowY="scroll">
      <Flex justifyContent="space-between" alignItems="center" mb="4">
        <Heading color={textColor} p={4} fontSize="xl">
          Sub Account
        </Heading>
      </Flex>
      {loading ? (
        <Loader />
      ) : (
        <Box height="auto">
          <Table
            fetch={hitFetch}
            columns={heads}
            data={tableData}
            table="subaccount"
          />
        </Box>
      )}
    </Box>
  );
};

export default SubAccountTable;
