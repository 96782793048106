import {
  Box,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { fetchController } from "../FetchController/fetchController";
import { getHostDomain } from "../utils";

const Editor2 = ({ setPrompt, prompt, isVarLoading }) => {
  const baseUrl = getHostDomain();
  const [isVariableModalOpen, setVariableModalOpen] = useState(false);
  const endpoint = "/api/v1/variable/list?page=1&size=50";
  const urlHit = baseUrl + endpoint;
  const [variables, setVariables] = useState([]);
  const [displayedPrompt, setDisplayedPrompt] = useState(prompt);

  const fetchVariables = async () => {
    const getData = await fetchController(urlHit, "GET");
    setVariables(getData.data.items);
  };

  useEffect(() => {
    fetchVariables();
  }, [isVarLoading]);

  const handleTextareaChange = (event) => {
    const value = event.target.value;
    setDisplayedPrompt(value);

    // Check if the user types '/'
    if (value.includes("/")) {
      setVariableModalOpen(true);
    }
  };

  const handleVariableSelect = (variable) => {
    // Find the last index of '/' in the description
    const lastSlashIndex = displayedPrompt.lastIndexOf("/");

    // Replace the text from the last '/' to the end with the selected variable
    const updatedDescription =
      displayedPrompt.slice(0, lastSlashIndex) +
      `{{${variable}}}` +
      displayedPrompt.slice(lastSlashIndex + 1);

    setPrompt(updatedDescription);
    setDisplayedPrompt(updatedDescription);
    setVariableModalOpen(false);
  };

  return (
    <Box height="455px">
      <FormControl>
        <FormLabel>Prompt</FormLabel>
        <Textarea
          width="100%"
          height="400px"
          borderColor="#3182ce"
          borderWidth="2px"
          value={displayedPrompt}
          onChange={handleTextareaChange}
          placeholder="Write your prompt here. Enter '/' to add a variable."
        />
      </FormControl>

      {/* Variable Selection Modal */}
      <Modal
        isOpen={isVariableModalOpen}
        onClose={() => setVariableModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Variable</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {variables &&
                variables.map((item) => (
                  <Box
                    key={item.label_key}
                    onClick={() => handleVariableSelect(item.label_key)}
                    cursor="pointer"
                    borderRadius="4px"
                    mt={1}
                    p={1}
                    bg="#f5f5f5"
                  >
                    <Text fontSize="14px" fontWeight="bold">
                      {item.name}
                    </Text>
                  </Box>
                ))}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Editor2;
