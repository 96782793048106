export const authTypeEnum = {
  OAUTH: "oauth",
  SIMPLE_TOKEN: "simple_token",
  OAUTH_KEYS: "oauth_keys",
};
export default function useNewAuthCreation({ tool, toggleKeyFields }) {
  const onOAuthAuthentication = () =>
    window.open(
      `${tool?.authentication?.oauth_url}&redirect_uri=${encodeURIComponent(
        process.env.REACT_APP_REDIRECT_URI
      )}`
    );
  const onKeysAuthentication = () => toggleKeyFields();

  const getNewAuthFunction = () => {
    const authMap = {
      [authTypeEnum.OAUTH]: onOAuthAuthentication,
      [authTypeEnum.SIMPLE_TOKEN]: onKeysAuthentication,
      [authTypeEnum.OAUTH_KEYS]: onKeysAuthentication,
    };
    return authMap[tool?.authentication?.method] || onKeysAuthentication;
  };
  return { getNewAuthFunction };
}
