import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useRef } from "react";

const ConfirmationModal = ({ onClose, isOpen, onConfirm, type, table }) => {
  const finalRef = useRef(null);
  const confirmationMessage =
    table === "data-sources"
      ? "Do you want to delete the data source?"
      : table === "assistant"
      ? "All the Captured Forms, Intents, Conversations & Widgets associated with this Assistant shall be deleted. Are you sure?"
      : table === "widget"
      ? "All the captured Forms, Intents and Conversations shall be deleted. Are you sure?"
      : table === "sourceFileList"
      ? "Do you want to delete the file ?"
      : table === "intent"
      ? "Do you want to delete the intent ?"
      : table === "webhooks"
      ? "Do you want to delete the webhook"
      : table === "conversation"
      ? "Do you want to delete this conversation ?"
      : table === "checkedConversationDelete" 
      ? "Do you want to delete selected conversation ?"
      : table === "contactCustomField" 
      ? "Do you want to delete this custom field ?"
      : table === "agencybillingPlan" 
      ? "Do you want to delete this Billing Plan ?"
      : "This action cannot be undone. Are you sure you want to proceed?";
      
  return (
    <>
      <Modal
        size="lg"
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent
          height="auto"
          width="95%"
          margin="auto"
          maxWidth="600px"
          minHeight="200px" // Set a minimum height to center vertically
        >
          <ModalHeader>Are you sure?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <Center> */}
              <Text fontSize={"lg"}>{confirmationMessage}</Text>
            {/* </Center> */}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onConfirm}>
              Yes
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Go back
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
