import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Stack,
} from "@chakra-ui/react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getHostDomain } from "../../utils/utils";
import { SettingContext } from "../Settings/Reducer/SettingProvider";
import { useContext } from "react";

function WebcallWidgetForm({ form }) {
  const { register, formState, watch } = form;
  const { widgetId } = useParams();
  const widget = watch();
  const baseUrl = getHostDomain();
  const { state: plan } = useContext(SettingContext);
  const remove_branding = !plan?.remove_branding;
  const onSubmit = async (data) => {
    try {
      const widgetToUpdate = {
        ...widget,
        style_params: data.style_params,
      };
      await fetchController(
        baseUrl + `/api/v1/widget/${widgetId}`,
        "PUT",
        widgetToUpdate
      );
      toast.success("Widget updated");
    } catch (error) {
      toast.error("Some error occured");
    }
  };
  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <FormControl isRequired>
          <FormLabel>Header Name</FormLabel>
          <Input {...register("style_params.header_name")} />
        </FormControl>
        <FormControl>
          <FormLabel>Logo</FormLabel>
          <Input {...register("style_params.logo")} />
        </FormControl>
        <FormControl
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          <FormLabel>Remove branding</FormLabel>
          <Checkbox
            isDisabled={remove_branding}
            {...register("style_params.remove_branding")}
          />
        </FormControl>
        <FormControl
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          <FormLabel>Record button color</FormLabel>
          <Input
            {...register("style_params.theme.magicBall.bgColor")}
            type="color"
            width={"100px"}
            borderRadius={"md"}
          />
        </FormControl>
        <Button
          margin={"auto"}
          loadingText="Submitting"
          isLoading={formState.isSubmitting}
          colorScheme="blue"
          type="submit"
        >
          Submit
        </Button>
      </Stack>
    </form>
  );
}
export default WebcallWidgetForm;
