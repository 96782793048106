import { useEffect, useState } from "react";
import Select from "react-select";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
export default function ToolSelect({ toolId, onChange }) {
  const baseUrl = getHostDomain();
  const [tools, setTools] = useState([]);
  useEffect(() => {
    (async () => {
      const response = await fetchController(
        baseUrl + "/api/v1/tool/list"
      );
      setTools(response.data.items);
    })();
  }, []);
  const options = tools.map((tool) => ({
    value: tool,
    label: tool.name,
  }));
  return (
    <Select
      required
      options={options}
      value={options.find((option) => option.value?.id === toolId)}
      onChange={onChange}
    />
  );
}
